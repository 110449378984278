import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer } from './InputContainers';

export const TextArea = forwardRef(({
  label,
  error,
  className = '',
  textSize,
  ...inputProps
}, ref) => {
  const { t } = useTranslation();

  return (
    <InputContainer className={className} textSize={textSize}>
      <label>
        {label && typeof label === 'string' ? <h6>{t(label)}</h6> : label}
        <textarea
          {...inputProps}
          placeholder={t(inputProps.placeholder || '')}
          rows={inputProps.rows || 3}
          ref={ref}
        />
      </label>
      <div className='input_info'>
        {error &&
          <i className='error'>{t(error)}</i>
        }
        {inputProps.maxlength &&
          <span className={`max_length ${(inputProps.value?.length || 0) >= inputProps.maxlength ? 'reached' : ''}`}>
            {inputProps.value?.length || 0}/{inputProps.maxlength}
          </span>
        }
      </div>
    </InputContainer>
  );
});