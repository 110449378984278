import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/solid";
import { centsToAmountString } from "logic/functions";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon, IconType } from "common/Icon";
import { TextArea } from "common/Input";
import { RelatedProductsPreview } from "../screens/RelatedProductsPreview";

export const ProductPopupMockupRelated = ({ name, product, relatedProducts, productsWithCompleteInfo }) => {
  const { t } = useTranslation();
  const [isExpandedComment, setIsExpandedComment] = useState(true);
  const [isExpandedList, setIsExpandedList] = useState(true);

  const id = `comment_${45464646}`;
  const idList = `list_${45464646}`;

  const toggleExpandedComment = () => {
    if (!isExpandedComment) document.getElementById(id)?.focus();
    setIsExpandedComment(prev => !prev);
  };

  const toggleExpandedList = () => {
    if (!isExpandedList) document.getElementById(idList)?.focus();
    setIsExpandedList(prev => !prev);
  };

  const productMockupDescription = "Lorem ipsum dolor sit amet consectetur. Volutpat vitae faucibus pellentesque id.Lorem ipsum dolor sit amet consectetur. Volutpat vitae faucibus pellentesque id."

  return (
    <MockupContainer style={{height: '438px', overflow: 'auto'}}>
      <div className="bg-white rounded-t-3xl w-72 mx-5 flex flex-col shadow-lg">
        <div className="flex-1 flex flex-col gap-[8px] p-4">
          <h3 className="text-[12px] leading-[1em] font-semibold">
            {product.name || product.visible_name || t("Product")}
          </h3>
          <p className="text-[9px]" style={{
            display: '-webkit-box',
            maxWidth: '300px',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}>
            {product.description || productMockupDescription}
          </p>

          {/*<MockAllergensAndTags product={product} showLabel/>*/}

          <div className="flex flex-row justify-between items-end px-2">
            <b className="text-[14px] font-bold">
              {centsToAmountString(product.tpv_price_cents).replace(".", ",")}€
            </b>
            <div className="flex flex-row gap-2">
              <MinusCircleIcon height={23} />
              1
              <PlusCircleIcon height={23} />
            </div>
          </div>
          <hr></hr>
          <Container isExpanded={isExpandedComment}>
            <h3 onClick={toggleExpandedComment}>
              {`💬 ${t('comment_for_kitchen')}`}
              <Icon type={IconType.ArrowDown}/>
            </h3>
            <p style={{fontSize: "12px", marginTop: "-10px"}}>{`(${t('optional')})`}</p>
            <div className='textAreaDiv'>
              <TextArea
                id={987989}
                textSize='12px'
                readOnly
                placeholder={t('comment_for_kitchen')}
                rows={1}
              />
            </div>
          </Container>
          <hr></hr>
          <Container isExpanded={isExpandedList}>
            <h3 style={{marginTop: "-10px"}} onClick={toggleExpandedList}>
              {name || t('list_title')}
              <Icon type={IconType.ArrowDown} color={"#000"}/>
            </h3>
            <RelatedProductsPreview products={relatedProducts} productsWithCompleteInfo={productsWithCompleteInfo}/>
          </Container>
        </div>
      </div>
    </MockupContainer>
  );
};

const MockupContainer = styled.div`    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: min-content;
  overflow: hidden;
  padding: 0px 16px 0;
  border-bottom: 1px solid #DDDDDD;
  flex-shrink: 0;
  
  & > * {
    border: 1px solid #DDDDDD66;
    box-shadow: 0 0 8px #959DA51A;
  }
`;


const Container = styled.div`
  color: ${({theme}) => theme.text};

  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    
    svg {
      color: ${({theme}) => theme.text_a50};
      rotate: ${({isExpanded}) => isExpanded ? '180deg' : '0'};
      transition: rotate 0.15s ease-in-out;
    }
  }
  
  .textAreaDiv {
    max-height: ${({isExpanded}) => isExpanded ? '74px' : '0'};
    overflow: hidden;
    margin: 0 -4px;
    padding: ${({isExpanded}) => isExpanded ? '4px' : '0 4px'};
    opacity: ${({isExpanded}) => isExpanded ? 1 : 0};
    transition: 0.15s ease-in-out;
    transition-property: max-height, opacity;
  }

  p {
    font-size: ${({theme}) => theme.font_xs};
    margin: 4px 0 0;
  }
`;
