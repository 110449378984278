import { useContext, useState, useEffect } from 'react';
import { Context } from 'data/authContext';
import { baseAuth } from 'logic/api';
import { translation } from "logic/api";
import { CustomToast } from 'common/CustomToast';

export const useRelatedProducts = () => {
	const { state: { selectedRestaurantId } } = useContext(Context);
  const [loadingUpdateRelatedProducts, setLoadingUpdateRelatedProducts] = useState(false)

  const updateRelatedProducts = async (relatedCustomGroup, products, product, editProduct, setProducts, onlyState=false) => {
    setLoadingUpdateRelatedProducts(true)
    const obj = {
      products: products,
      product: product,
      active: relatedCustomGroup ? onlyState ? !relatedCustomGroup.active : relatedCustomGroup.active : true,
      restaurant: selectedRestaurantId,
      only_state: onlyState
    }

    try {
      const response = await baseAuth.post(`/custom_group/update_related_products_custom_group`, obj)

      const related_products_custom_group = response?.data?.related
      const related_products_custom_group_products = related_products_custom_group?.products_info?.sort((a,b) => a.order - b.order);

      editProduct({ custom_groups: related_products_custom_group });

      if (!onlyState) {
        setProducts(related_products_custom_group_products)
      }

      const customGroupId = related_products_custom_group_products?.[0]?.custom_group;
      console.log("Inside updateRelatedProducts:", customGroupId);

      return customGroupId; 

    } catch (e) {
        console.error(`Error in update_related_products_custom_group: ${e}`);
        return null;
    } finally {
      setLoadingUpdateRelatedProducts(false);
    }

  };

  const useTranslationsRelatedProducts = (customGroupId, selectedRestaurant, defaultLanguage) => {
    const [segmentsToTranslate, setSegmentsToTranslate] = useState([]);
    const [translatedLanguages, setTranslatedLanguages] = useState([]);
    const [loadingTranslation, setLoadingTranslation] = useState(false);

    const getData = async (endpoint) => {
        let isMounted = true;

        if (isMounted) {
          setLoadingTranslation(true);
            const restaurantId = selectedRestaurant[0].value;
            let completeEndpoint = `${endpoint}?custom_group_id=${customGroupId}&restaurant=${restaurantId}`;

            try {
                const response = await translation.get(completeEndpoint);
                const newSegments = response?.data?.rows || [];
                setSegmentsToTranslate(newSegments);

                const customGroupInfo = response?.data?.rows?.[customGroupId];
                const existingLanguages = Object.keys(customGroupInfo).filter(
                    lan => customGroupInfo[lan] !== null && customGroupInfo[lan]["value"] !== ''
                );

                const languagesResponse = response?.data?.languages || [];
                const languagesDone = languagesResponse.filter(lan => existingLanguages.includes(lan) && lan !== defaultLanguage);
                if (isMounted) {
                    setTranslatedLanguages(languagesDone);
                }
            } catch (error) {
                console.error(`Error getting translation data: ${error}`);
                CustomToast('Error fetching translation data', 'error');
            } finally {
              if(isMounted){
                setLoadingTranslation(false);
              }
            }
        }
    };

    useEffect(() => {
        if (customGroupId) {
            getData('/custom_group_name_translation');
        }
    }, [customGroupId, selectedRestaurant, defaultLanguage]);

    return { 
      segmentsToTranslate, 
      translatedLanguages, 
      loadingTranslation, 
      setTranslatedLanguages 
    };
  };

  return {
    loading: loadingUpdateRelatedProducts,
    updateRelatedProducts,
    useTranslationsRelatedProducts
  };
};


