import { components } from 'react-select';
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../data/authContext';
import { baseAuth } from "logic/api";
import { SelectInput } from 'common/Input';

export const CategorySelect = () => {
    const {
		state: { selectedRestaurant, selectedDate, selectedDateTo, selectedTables },
    setTopProducts,
    setTopModifiers,
    setSelectedProductCategories,
	} = React.useContext(AuthContext);

	const { t } = useTranslation();

  const [productCategories, setProductCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
	
  const handleChangeOption = (value) => {
    const result = value.map(option => productCategories.find(cat => cat.id === option.id))
    setSelectedCategories(result);
    setSelectedProductCategories(result)
  }

    const selectorOptions = productCategories.map((product_category) => ({
        "label": product_category.name ? product_category.name : product_category.tpv_name,
        "value": product_category.id,
        "name": product_category.name,
        "tpv_name": product_category.tpv_name,
        "id": product_category.id,
    }));
    
    const Option = (props) => {
        return (
          <div>
            <components.Option {...props}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <label>{props.label}</label>
              <input
                className="checkboxRestaurantSelect"
                type="checkbox"
                checked={selectedCategories.find((s_c) => s_c.id === props.value)}
                onChange={() => null()}
              />{" "}
              <span></span>
              </div>
            </components.Option>
          </div>
        );
      };

    useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setProductCategories([])
		if (selectedRestaurant?.length && selectedDate, selectedDateTo, !isLoading) {
			getData();
		}
	}, [selectedRestaurant, selectedDate, selectedDateTo, selectedTables])

	const getData = async () => {
    setIsLoading(true);
    let startDate = new Date(selectedDate);
    startDate.setHours( 0,0,0,0 );
    startDate = startDate.getTime();
    let endDate = new Date(selectedDateTo);
    endDate.setHours( 23,59,59,0 );
    endDate = endDate.getTime();

    const restaurants = selectedRestaurant.reduce((str, restaurant) => str += `restaurant=${restaurant.value}&`, '');
    let endpoint = `/restaurant/top-products?${restaurants}from=${startDate}&to=${endDate}`
    if (selectedTables.length > 0) {
      const tables = selectedTables.reduce((str, table) => str += `table=${table.id}&`, '');
      endpoint += `&${tables}`
    }
		await baseAuth.get(endpoint)
			.then((response) => {
        const {
          product_categories,
          top_products,
          top_modifiers,
        } = response.data;

				setProductCategories(product_categories);
        setSelectedProductCategories(product_categories);
        setTopProducts(top_products);
        setTopModifiers(top_modifiers);
			})
			.catch((e) => {
        console.log(`CategorySelect error: ${e}`)
			})
      .finally(() => setIsLoading(false));
	};

  return(
    <SelectInput
      label='Categorías'
      id='product_categories'
      placeholder='All'
      isMulti={true}
      multiValue={selectorOptions.filter(option => selectedCategories.some(cat => cat.id === option.id))}
      options={selectorOptions}
      className={'w-80'}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      onChange={handleChangeOption}
      components={{Option}}
      isClearable={false}
    />
  );
};



