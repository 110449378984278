import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import useUpdateTableData from 'components/hooks/useUpdateTableData';
import { Context as AuthContext } from '../../../../data/authContext';
import { toast } from 'react-toastify';
import { ChipWithToggle } from 'common/ChipWithToggle';
import { InfoTooltip } from 'common/InfoTooltip';

const ToggleOrdersButton = ({ isCatalogLoading, className, onChange }) => {
  const {
    state: { selectedRestaurant },
    setSelectedRestaurant,
  } = React.useContext(AuthContext);

  const { t } = useTranslation();
  
  const seleccionado = JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) || selectedRestaurant;
  let id = 0;

  if (Array.isArray(seleccionado) && seleccionado[0].label !== 'Todos') {
    id = seleccionado[0].value
  } else if (!Array.isArray(seleccionado) && seleccionado && seleccionado.label !== 'Todos') {
    id = seleccionado.value
  }

  const { updateData, error: updatedError } = useUpdateTableData({
    entidad: 'restaurants-admin-new',
    id,
  });

  const [activated, setActivated] = useState(selectedRestaurant && selectedRestaurant[0] && selectedRestaurant[0].is_order_active);
  const [isLoading, setIsLoading] = useState(false);

  const toggleActive = () => {
    if (selectedRestaurant[0].label === 'Todos' || id === 0) return;

    const isActive = !activated

    const sendData = async () => {
      setIsLoading(true);

      await updateData({ "is_order_active": isActive });
      if (!updatedError) {

        setActivated(isActive);
        selectedRestaurant[0].is_order_active = isActive;

        setSelectedRestaurant(selectedRestaurant)
        
        let restaurantes = JSON.parse(localStorage.getItem('yumminn-restaurants'));

        restaurantes.forEach((item) => {
          if(item.value === selectedRestaurant[0].value){
            item.is_order_active = isActive
          }
        });

        localStorage.setItem('yumminn-restaurants', JSON.stringify(restaurantes))
        toast.success(t('update_ok'));
      } else {
        toast.error(t('update_error'));
      }
      setIsLoading(false);
    };

    if (id !== 0 && !isLoading && !isCatalogLoading) sendData();
  };

  useEffect(() => {
    if (selectedRestaurant && selectedRestaurant[0]) {
      setActivated(selectedRestaurant[0].is_order_active);
    }
  }, [selectedRestaurant]);

  return (
    <ChipWithToggle
      name={'toggle_orders'}
      checked={activated}
      loading={isLoading}
      disabled={isCatalogLoading}
      onChange={toggleActive}
      className={className}
      label={
        <span className='flex gap-1 items-center'>
          {t('Orders')}
          <InfoTooltip info={activated ? 'Orders activated' : 'Orders deactivated'}/>
        </span>
      }
    />
  );
};

export default ToggleOrdersButton;