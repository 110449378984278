import { Icon, IconType } from "common/Icon";
import React from "react";
import styled from "styled-components";

const flagTypes = {
    es: IconType.SPANISH,
    ca: IconType.CATALAN,
    en: IconType.ENGLISH,
    de: IconType.GERMAN,
    fr: IconType.FRENCH,
    it: IconType.ITALIAN,
    pt: IconType.PORTUGUESE,
};

const LanguagesTranslatedFlags = ({translatedLanguages}) => {

  if(!translatedLanguages){
    return <></>
  }

  return (
    <FlagsWrapper>
        {translatedLanguages.map((lang, index) => 
            <Icon className='flag' key={`lang-${index}`} type={flagTypes[lang]} />
        )}  
    </FlagsWrapper>
  )
};

const FlagsWrapper = styled.div`
    display: flex;
    
    svg:first-child{
        margin-left: 0;
    }

    .flag{
        margin-left: -8px; 
        border-radius: 9999px;
        outline: 3px solid white;
    }
`;

export {LanguagesTranslatedFlags}