import React from 'react';
import '../../../styles/animations.css';
import { SuggestionProductCardMockup } from '../components/SuggestionProductCardMockup';
import styled from "styled-components";

export const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SuggestionsContainer = styled.div`
  display:grid;
  grid-template-columns: auto auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  margin: 5px 0px 0px 0px;
`;

export const RelatedProductsPreview = ({products, productsWithCompleteInfo}) => {

  const cardsToShow = 99
  return (
    <SuggestionsWrapper>
      <SuggestionsContainer id="scroll">
        {products.slice(0, cardsToShow).map((product, index) => {
          const completeProduct = productsWithCompleteInfo.find(prod => prod.id === product.product_id) || product;
          return(
            <SuggestionProductCardMockup key={`related_product_${index}`} product={completeProduct} bigger/>
          )
        })}
      
      {/*(Array.from(Array(Math.abs(products.length < 4 ? products.length - 4 : 0)).keys())).map((idx) => (
        <SuggestionProductCardMockup key={`related_product_no_product${idx}`} product={null} bigger/>
      ))*/}
      </SuggestionsContainer>
    </SuggestionsWrapper>
  );
};