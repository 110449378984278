import styled, { css } from 'styled-components';

const fonts = css`
  & > h2, & > h3 {
    font-weight: 600;
    color: #094553;
  }

  & > h2 {
    font-size: 1.5rem;
  }

  & > h3 {
    font-size: 1.5rem;
  }
  
  & > p {
    font-size: 1rem;

    a {
      color: #094553;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

const ButtonsFooter = styled.div`
    display: flex; 
    justify-content: space-between; 
    gap: '24px'; 
    margin-top: 20px;
`;

const ButtonsFooterOneButton = styled.div`
    display: flex; 
    justify-content: center; 
    margin-top: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  header {
    display: flex; 
    justify-content: space-between; 
    gap: 50px;

    ${fonts}
  }
  
`;

const ColumnContainer = styled(RowContainer)`
  flex-direction: column;
  font-weight: 500;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: auto!important;
    margin: 0!important;
  }

`;

const RelatedProductsContainer = styled.div`

  //overflow-y: auto; 
  //max-height: 100vh;
  
  & > div:last-child {
    display: flex;
    flex-direction: column;

    h6 {
      font-size: 1rem;
      font-weight: 300;
      font-weight: 400;
      margin: 0 0 8px;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    b {
      font-weight: 600;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1 1 0;
      overflow-y: auto;
      list-style: none;
    }

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: #F8F8F8;
      border-radius: 0.5rem;
      padding: 8px 16px;
      user-select: none;

      svg {
        height: 0.875rem;
        width: 0.875rem;
      }

      button {
        margin-left: auto;
      }
    }
  }
`;



export {ButtonsFooter, ColumnContainer, ButtonsFooterOneButton, RelatedProductsContainer}