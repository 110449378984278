import { InfoTooltip } from 'common/InfoTooltip';
import { TextArea, NumberInput, SelectInput } from 'common/Input';
import { NutritionalValuesPreview } from 'components/previews';
import { calculateCalories } from 'logic/functions';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const FIELDS = ['fats', 'saturated_fats', 'carbohydrates', 'fiber', 'sugars', 'proteins', 'sodium'];

export const NUTRITIONAL_FIELDS = ['serving_size', ...FIELDS];

const UNIT_OPTIONS = ['g', 'ml'].map(value => ({label: value, value}));

export const NutritionalInfo = ({ item, isTspoonlab, editItem }) => {
  const { t } = useTranslation();

  const unit = item.serving_measurement_unit || 'g';
  const calories = calculateCalories(item);

  const editValues = (event, field) => {
    const value = event.target.value;
    const itemPatch = {[field]: value};

    const numValue = Number(value);

    if (value) {
      if (field === 'saturated_fats' && numValue) {
        itemPatch.fats = Math.max((item.fats || 0), numValue);
      }

      if (['fiber', 'sugars'].includes(field) && numValue) {
        const carbsSum = numValue + Number(item[field === 'fiber' ? 'sugars' : 'fiber'] || 0);
        itemPatch.carbohydrates = Math.max((item.carbohydrates || 0),carbsSum);
      }
    }

    editItem(itemPatch);
  };

  const sumMacros = (event, field) => {
    if (!['fats', 'carbohydrates'].includes(field)) return;

    const value = event.target.value;
    
    if (field === 'fats') {
      if (!value && !Number(item.saturated_fats || 0)) {
        editItem({[field]: value});
      } else {
        editItem({[field]: Math.max((item.saturated_fats || 0), Number(value))});
      }
    }

    if (field === 'carbohydrates') {
      const carbsSum = Number(item['sugars'] || 0) + Number(item['fiber'] || 0);
      
      if (!value && !carbsSum) {
        editItem({[field]: value});
      } else {
        editItem({[field]: Math.max(carbsSum, Number(value))});
      }
    }
  };

  return (
    <NutritionalInfoContainer>
      <div>
        <TextArea
          label={
            <h6 className="flex gap-2 items-center">
              {t('ingredients')}
              <InfoTooltip>
                <ul style={{listStyle: 'none', width: 'max-content', padding: 0}}>
                  <Trans
                    i18nKey={'format_text_info'}
                    defaults={'<li>**bold** ➡ <b>bold</b></li><li>//italic// ➡ <i>italic</i></li><li>__underline__ ➡ <u>underline</u></li>'}
                    components={{ li: <li/>, b: <b/>, i: <i/>, u: <u/> }}
                  />
                </ul>
              </InfoTooltip>
            </h6>
          }
          placeholder='write here'
          value={item.ingredients}
          onChange={e => editItem({ingredients: e.target.value})}
          rows={2}
        />
        <div className='preview'>
          <NutritionalValuesPreview item={item}/>
        </div>
      </div>
      <div>
        {!isTspoonlab &&
          <i>
            <Trans i18nKey={'nutritional_info_explanation'}>
              Coloca un <b>valor igual o mayor a 0 (cero)</b> en las casillas para que la información sea visible. Las casillas vacías estarán ocultas en la webapp
            </Trans>
          </i>
        }
        <div className='nutritional_fields'>
          <NumberInput
            label='serving_size'
            value={item.serving_size}
            placeholder='0'
            disabled={isTspoonlab}
            onChange={e => editItem({serving_size: e.target.value})}
          />
          <SelectInput
            value={UNIT_OPTIONS.find(({value}) => value === unit)}
            options={UNIT_OPTIONS}
            disabled={isTspoonlab}
            className='unit_selector'
            onChange={option => editItem({serving_measurement_unit: option.value})}
          />
          {FIELDS.map(field =>
            <NumberInput
              key={`input_${field}`}
              label={`${t(field)}/100${unit}`}
              value={item[field]}
              disabled={isTspoonlab}
              unit='g'
              placeholder={`(${t('not_visible').toLowerCase()}) 0`}
              onChange={e => editValues(e, field)}
              onBlur={e => sumMacros(e, field)}
            />
          )}
        </div>
        <span>
          {t('energy') + ': '}
          <b>
            {calories === null ? t('not_specified') : calories + 'kcal'}
          </b>
        </span>
      </div>
    </NutritionalInfoContainer>
  );
};

const NutritionalInfoContainer = styled.div`
  display: flex;
  gap: 48px;
  padding: 24px;
  flex: 1;
  max-height: 100%;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 100%;
    padding: 8px;
    margin: -8px;

    &:last-of-type {
      overflow: hidden auto;
    }

    & > i, & > span {
      padding: 8px;
      border-radius: 8px;
    }

    & > i {
      font-size: 0.875rem;
      background-color: #FFF5DC;
      max-width: 50ch;
    }

    & > span {
      font-size: 1rem;
      background-color: #F8F8F8;
      text-align: center;
      display: block;

      &::first-letter {
        text-transform: capitalize;
      }

      b {
        display: inline-block;
        
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    &:first-of-type {
      position: sticky;
      top: 0;
    }
  }

  b {
    font-weight: 600;
  }

  .preview {
    flex: 1;
    height: 0;
    display: flex;
    align-items: center;
  }

  .nutritional_fields {
    display: grid;
    grid-template-columns: repeat(2, minmax(14rem, 1fr));
    gap: 16px;
    flex: 1;
    align-items: end;
  }

  .unit_selector {
    width: 50%;
  }
`;