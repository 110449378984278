import React, { useState, useEffect, useContext } from "react";
import Spinner from "common/Spinner";
import RestaurantSelect from "components/yumminn/RestaurantSelect";
import OrdersTable from "./Orders/OrdersTable";
import DateRangeSelect from "components/yumminn/DateRangeSelect";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import RoundPagination from "common/RoundPagination";
import OrderDetail from "./Orders/OrderDetail";
import StatusSelect from "./Orders/StatusSelect";
import OrderSearchInput from "./Orders/OrderSearchInput";
import OrderNotification from "../../../sound/OrderNotification.mp3"
import VolumeSlider from "./Orders/VolumeSlider";
import styled from "styled-components";
import MenuHeader from 'pages/home/components/overview/components/MenuHeader';
import { orderAndPay } from "logic/api";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';
import { NavigatorContext } from 'data/navigatorContext';
import { Howl } from "howler";
import { useOrders } from "./useOrders";
import { cloneDeep } from "lodash";
import { KitchenSaturation } from "./KitchenSaturation";
import { LoadingOverlay } from "common/LoadingOverlay";

const OrdersHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
const RecommendationFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-top: 15px;
`;
const RecommendationFiltersLabel = styled.p`
  align-self: center;
  margin-right: 5px;
  min-width: fit-content;
`;
const RecommendationWrapper = styled.div`
  display: flex;
  flex-direction: row;
	font-size: 12.5px;
	border-radius: 20px;
	padding: 6px;
  padding-left: 6px;
  margin-left: 5px;
  background-color: rgb(95, 184, 148);
  min-width: fit-content;
  span {
    color: white;
  }
`;
const RecommendationType = styled.span`
	font-size: 11.5px;
	background-color: rgb(115, 204, 168);
	color: hsl(0, 0%, 20%);
	border-radius: 20px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-right: 5px;
`;
const RecommendationValue = styled.span`
	color: hsl(0, 0%, 20%);
  align-self: center;
`;
const RecommendationCloseButton = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  span {
    font-weight: 600;
    color: #404040;
    align-self: center;
  }
  &:hover {
    cursor: pointer;
    background-color: rgb(115, 204, 168);
  }
  &:hover span {
    color: white;
  }
`;

const Orders = () => {
  const { state: { selectedRestaurant, selectedDate, selectedDateTo } } = useContext(AuthContext);
  const { state: { open } } = useContext(NavigatorContext);

  const { t } = useTranslation();

  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants') || '[]');
  const storedVolume = JSON.parse(localStorage.getItem('notificationVolume') || '0');

  const hasActiveOrderAndPayTab = restaurants.some(item => item.show_order_and_pay_tab_on_backoffice);
  const elementsPerPage = 50;

  const [selectedOrder             , setSelectedOrder]              = useState(null);
  const [numberOfPages             , setNumberOfPages]              = useState(null);
  const [totalNumberOfOrders       , setTotalNumberOfOrders]        = useState(null);
  const [orders                    , setOrders]                     = useState([]);
  const [products                  , setProducts]                   = useState([]);
  const [productsModifiers         , setProductsModifiers]          = useState([]);
  const [modifiersModifiers        , setModifiersModifiers]         = useState([]);
  const [selectedStates            , setSelectedStates]             = useState([]);
  const [incomingOrdersIds         , setIncomingOrdersIds]          = useState([]);
  const [notOpenedIncomingOrdersIds, setNotOpenedIncomingOrdersIds] = useState([]);
  const [recommendationFilters     , setRecommendationFilters]      = useState([]);
  const [selectedVolume            , setSelectedVolume]             = useState(storedVolume);
  const [page                      , setPage]                       = useState(1);
  const [loading                   , setLoading]                    = useState(false);
  const [statusLoading             , setStatusLoading]              = useState(false);
  const [notifyNew                 , setNotifyNew]                  = useState(false);

  const { data, isLoading, mutate } = useOrders({ page, recommendationFilters, selectedStates, setNotifyNew });
  
  const recommendationTypeToString = {
    'address':      t('Address'),
		'client_name':  t('Client_name'),
		'client_phone': t('Phone'),
		'client_email': t('Email_short'),
    'code':         '#' + t('Order'),
	};

  let Sound;

  const playSound = () => {
    if (Sound === undefined) {
      Sound = new Howl({
        src: [OrderNotification],
        volume: selectedVolume
      });
    }

    Sound.play();
};

  const updateData = data => {
    try {
      const filtered_orders = data.orders;

      setOrders(filtered_orders);
      setProducts(data.products);
      setProductsModifiers(data.products_modifiers);
      setModifiersModifiers(data.modifiers_modifiers);

      if (totalNumberOfOrders && totalNumberOfOrders > 0 && data?.total_number_of_orders > 0 && notifyNew) {
        const newOrdersCount = data.total_number_of_orders - totalNumberOfOrders;

        if (newOrdersCount > 0) {
          const incomingIds = data.orders.slice(0, newOrdersCount).map(ord => ord.id);
          const updatedNotOpenedIds = incomingOrdersIds.concat(incomingIds);

          setIncomingOrdersIds(incomingIds);
          setNotOpenedIncomingOrdersIds(updatedNotOpenedIds);
          playSound();
        } else {
          setIncomingOrdersIds([]);
        }
      } else {
        setIncomingOrdersIds([]);
      }

      if (filtered_orders.length > 0 && (!selectedOrder || !filtered_orders.some(o => o.id === selectedOrder.id))) {
        setSelectedOrder(filtered_orders[0]);
      }

      const incomingNumberOfOrders = data.total_number_of_orders;
      setTotalNumberOfOrders(incomingNumberOfOrders);
  
      const nextNumberOfPages = Math.ceil(incomingNumberOfOrders / elementsPerPage);
      setNumberOfPages(nextNumberOfPages > 0 ? nextNumberOfPages : null);
    } catch (error) {
      console.log(`Orders.jsx:getData ~ catch: ${error.toString()}`);
    } finally {
      setLoading(false);
      setStatusLoading(false);
      !notifyNew && setNotifyNew(true);
    }
  };

  const changeStatus = (id, status) => {
    setStatusLoading(true);

    const endpoint = `/update_order_status`;
    const body = {"id": id, "status": status};

    orderAndPay
      .post(endpoint, body)
      .then(() => {
        const orders = cloneDeep(data.orders);
        const orderIndex = orders.findIndex(order => order.id === id);
        
        orders[orderIndex].status = status;

        mutate({ ...data, orders });
      })
      .catch(err => console.log(`Orders.jsx:changeStatus ~ catch: ${err.toString()}`))
      .finally(() => setStatusLoading(false));
  };

  const addRecommendationFilter = newFilter => {
    const newRecommendationFilters = recommendationFilters.concat([newFilter]);
    setRecommendationFilters(newRecommendationFilters);
  };

  const removeRecommendationFilter = indexToRemove => {
    const newRecommendationFilters = recommendationFilters.filter((_, index) => index != indexToRemove);
    setRecommendationFilters(newRecommendationFilters);
  };

  const reSendOrder = (id) => {
    setStatusLoading(true)
    const endpoint = `/resend-order-to-pos`;
    const body = {"id": id}
    orderAndPay
      .post(endpoint, body)
      .then(res => {
        updateData(data)
      })
      .catch(err => {console.log(`Orders.jsx:reSendOrder ~ endpoint=${endpoint} catch: ${err.toString()}`);setStatusLoading(false)
    })
  }

  const rePrintOrder = (id) => {
    setStatusLoading(true)
    const endpoint = `/reprint-order-yumminn`;
    const body = {"id": id}
    orderAndPay
      .post(endpoint, body)
      .then(res => {
        updateData(data)
      })
      .catch(err => {console.log(`Orders.jsx:rePrintOrder ~ endpoint=${endpoint} catch: ${err.toString()}`);setStatusLoading(false)
    })
  }

  useEffect(() => {
    setPage(1);
    setNumberOfPages(null);
    setNotifyNew(false);
  }, [selectedRestaurant, selectedDate, selectedDateTo, selectedStates, recommendationFilters]);

  useEffect(() => {
    if (!data) return;
    updateData(data);
  }, [data]);

  if (!hasActiveOrderAndPayTab) return <UnavailableFunctionality title={'Orders'} />

  return (
    <>
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`} >
        <MenuHeader title={"Orders"} route_group={'payments_and_orders'}/>
        <div className={`flex flex-col lg:flex-row justify-between items-center w-full px-[32px] gap-5 bg-white py-4`}>
          <div className={`flex flex-col lg:flex-row items-center w-full gap-5 bg-white`}>
            <RestaurantSelect />
            <DateRangeSelect hourFormat={false} defaultRange='today' open={open}/>
          </div>
        </div>
        {(isLoading || loading) && <LoadingOverlay/>}
        <div className={`flex-auto px-[32px] w-full mt-4`}>
          <OrdersHeader>
            <div className={`gap-5`} style={{display: 'flex', flexDirection: 'row'}}>
              <OrderSearchInput addFilter={addRecommendationFilter} />
              <StatusSelect selectedStates={selectedStates} setSelectedStates={setSelectedStates}/>
              <VolumeSlider name={'notificationVolume'} selectedVolume={selectedVolume} setSelectedVolume={setSelectedVolume}/>
              <KitchenSaturation saturationList={data?.saturation} mutate={mutate}/>
            </div>
            {recommendationFilters?.length > 0 && (
              <RecommendationFiltersWrapper>
                <RecommendationFiltersLabel>{t('filtering_by') + ":"}</RecommendationFiltersLabel>
                {recommendationFilters?.map((recommendation, index) => {
                  if (!recommendation) {
                    return <></>;
                  }
                  return (
                    <RecommendationWrapper key={'recommendation_' + index}>
                      <RecommendationType>
                        {recommendationTypeToString[recommendation.type]}
                      </RecommendationType>
                      <RecommendationValue>
                        {recommendation.value}
                      </RecommendationValue>
                      <RecommendationCloseButton onMouseDown={() => removeRecommendationFilter(index)}>
                        <span>X</span>
                      </RecommendationCloseButton>
                    </RecommendationWrapper>
                  );
                })}
              </RecommendationFiltersWrapper>
            )}
            {numberOfPages && (
              <RoundPagination
                page={page}
                numberOfPages={numberOfPages}
                setPage={setPage}
                total={totalNumberOfOrders}
                elementNamePlural={t("orders")}
                elementNameSingular={t("order")}
              />
            )}
          </OrdersHeader>
          <div style={{display: 'flex', gap: '1%', zIndex: 0}}>
            <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: '59%'}}>
              <OrdersTable
                orders={orders}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                incomingOrdersIds={incomingOrdersIds}
                notOpenedIncomingOrdersIds={notOpenedIncomingOrdersIds}
                setNotOpenedIncomingOrdersIds={setNotOpenedIncomingOrdersIds}
              />
            </div>
            {selectedOrder && orders?.find((od => od.id === selectedOrder.id)) &&
            <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px", width: '40%', height: 'min-content'}}>
              <OrderDetail
                order={orders?.find((od => od.id === selectedOrder.id))}
                products={products.filter(pr => pr.order_request === selectedOrder.id)}
                productsModifiers={productsModifiers}
                modifiersModifiers={modifiersModifiers}
                changeStatus={changeStatus}
                statusLoading={statusLoading}
                reSendOrder={reSendOrder}
                rePrintOrder={rePrintOrder}
              />
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
