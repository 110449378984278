import XButton from 'common/XButton';
import { apiAuth } from 'logic/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {toast} from 'react-toastify';
import {sayError} from "../../../logic/defaults";
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import Spinner from 'common/Spinner';

export default function CompaniesList() {
  const { t } = useTranslation();
  const history = useHistory();
  const [refresh, setRefresh] = React.useState(false)
  const isRowBased = useMediaQuery("(max-width: 760px)");
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const [error, setError] = useState(false)

  const handleDelete = async (r) => {
    try {
      await apiAuth.delete(`/companies/${r.id}`);
      toast.success(`${t('company_deleted')}`);
      history.push('/admin/companies')
      setRefresh(true)
    } catch (error) {
      toast.error(sayError(error.response.data));
    }
  }


  const getDataCompanies = async () => {
    setLoading(true)
    try {
      const res_obj = await apiAuth.get(`companies/get_companies_bo_data/`)
      setLoading(false)
      const res_data = res_obj.data.results;
      setData(res_data);
    } catch (error) {
      setLoading(false)
      setError(true)
      toast.error(error?.response?.data);
    }
  }


  React.useEffect(() => {
    getDataCompanies();
  }, [refresh]);


  /*const passKYC = async (companyId) => {
    try {
      const res_obj = await apiAuth.post(`/companies/${companyId}/pass_kyc/`);
      if (res_obj?.status == 200) {
        toast.success("KYC actualizado correctamente");
        setRefresh(true);
      } else {
        toast.error("Error actualizando valor");
      }
    } catch (error) {
      toast.error(error.toString());
    }
  };*/

  if (loading) {
    return <div style={{marginTop: "143px", background: isRowBased ? 'white' : ''}}><Spinner>{t('Loading')}...</Spinner></div>;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (!data) return null;

  if (data) {

    return (
      <div>
        <div className={`${isRowBased ? 'flex bg-white justify-between w-full items-center' : 'self-end my-5 w-full'}`}>
        {isRowBased && <div style={{fontSize: '14px', padding: "0 15px"}}>{t('Create new company')}:</div>}
          <XButton
            className='btn btn-primary rounded-full px-12'
            type='button'
            doClick={() => history.push('/admin/companies/new')}
          >
            {t('New_company')}
          </XButton>
        </div>

        <div className={`${isRowBased ? 'pl-4 pb-4 pt-8 bg-white rounded-lg' : 'mb-5 mr-3 bg-white p-3 rounded-lg min-w-[420px]'}`}>
          <table width='100%' className='grid-cols-4'>
            <thead>
              <tr className='text-left bg-white border-b border-b-primary py-5'>
                <th>{t('Name')}</th>
       
                <th>{t('Owner')}</th>

                {!isRowBased && <th>{t('Restaurants')}</th>}

                <th>{t('Options')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((r) => (
                <tr className='py-3' key={r.id}>
                  <td style={{fontSize: isRowBased ? '14px' : '', width: isRowBased ? "150px" : ""}}>{r.name}</td>
                  <td style={{fontSize: isRowBased ? '14px' : '', width: isRowBased ? "100px" : ""}}>{r.owner_name}</td>
                  {!isRowBased && <td style={{textAlign: 'center'}}>{r.total_restaurants}</td>}
                  <td align='right'>
                    <div className='flex flex-row'>
                      <XButton
                        className='btn btn-primary rounded-full px-12'
                        type='button'
                        doClick={() =>
                          history.push(`/admin/companies/${r.id}`)
                        }
                      >
                        {isRowBased ? 
                        <PencilIcon 
                          className="h-5 w-5 text-white-500"
                        />
                        :
                        <div>{t('edit')}</div>} 
                      </XButton>
                      
                      {/* <XButton
                        className='btn btn-primary rounded-full px-4'
                        type='button'
                        doClick={() => passKYC(r.id)}
                        disabled={r.adyen_kyc_passed}
                      >
                        <div style={{whiteSpace: 'nowrap'}}>{r.adyen_kyc_passed ? t('KYC passed') : t('Pass KYC')}</div>
                      </XButton> */}

                      <XButton
                        className='btn btn-primary rounded-full px-12'
                        type='button'
                        doClick={() => handleDelete(r)}
                      >
                        {isRowBased ? 
                        <TrashIcon 
                          className="h-5 w-5 text-white-500"
                        />
                        :
                        <div>{t('delete')}</div>} 
                      </XButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
