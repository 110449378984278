const TEXT_TAGS = ["p", "b", "i", "u", "em", "strong", "span", "code"];

const makeRegex = (srt) => new RegExp(`(${srt})(.*?)\\1`, 'g');

const customMarkup = [
  { tag: "b", regex: makeRegex('\\*\\*') },
  { tag: "i", regex: makeRegex('//') },
  { tag: "u", regex: makeRegex('__') },
];

const parseStyle = (styleString) => {
  if (!styleString) return {};

  return styleString.split(";").reduce((styleObj, rule) => {
    const [key, value] = rule.split(":").map(str => str.trim());

    if (key && value) {
      const camelCasedKey = key.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

      styleObj[camelCasedKey] = value;
    }

    return styleObj;
  }, {});
};

const convertToJsx = (node, index) => {
  if (node.nodeType === Node.TEXT_NODE || !(node instanceof Element)) return node.textContent;

  const Tag = node.tagName.toLowerCase();

  if (!TEXT_TAGS.includes(Tag)) return node.textContent;

  const children = Array.from(node.childNodes).map(convertToJsx);

  return <Tag key={index} style={parseStyle(node.getAttribute("style"))}>{children}</Tag>;
};

export const FormatedText = ({ text }) => {
  if (!text) return null;
  
  const htmlString = customMarkup.reduce((str, { tag, regex }) =>
    str.replace(regex, `<${tag}>$2</${tag}>`)
  , text);

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  
  return <>{Array.from(doc.body.childNodes).map(convertToJsx)}</>;
};