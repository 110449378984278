import React, { useState } from 'react'; 
import { TextInput } from '.';
import { LanguagesTranslatedFlags } from 'pages/admin/pages/OrderAndPay/LanguagesTranslatedFlags';
import TranslationsPopup from 'pages/admin/pages/OrderAndPay/Modals/TranslationsPopup';

const LanguageSelectorWithPopup = ({ 
    value, 
    placeholder,
    translationKey, 
    translationsDone, 
    availableLanguages, 
    languages, 
    setName, 
    name,
    updateTranslation,
    segmentsToTranslate,
    translatedLanguages,
    loadingTranslation,
    setTranslatedLanguages  
  }) => {
    const [popupOpened, setPopupOpened] = useState(false);
  
    return (
      <div>
        <TextInput
          className="cursor-pointer max-w-[30rem]"
          value={value}
          placeholder={placeholder}
          onClick={() => setPopupOpened(true)}
          rightIcon={<LanguagesTranslatedFlags translatedLanguages={translationsDone} />}
        />
  
        <TranslationsPopup
          open={popupOpened}
          close={() => setPopupOpened(false)}
          translationKey={translationKey}
          availableLanguages={availableLanguages}
          languages={languages}
          updateTranslation={updateTranslation}
          setName={setName}
          name={name}
          segmentsToTranslate={segmentsToTranslate} 
          translatedLanguages={translatedLanguages} 
          loadingTranslation={loadingTranslation} 
          setTranslatedLanguages={setTranslatedLanguages} 
        />
      </div>
    );
  };
  
  export default LanguageSelectorWithPopup;