import React, { useState, useContext } from 'react';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import { Popup } from 'common/Popup';
import { useTranslation } from 'react-i18next';
import { translation } from "logic/api";
import { Context } from 'data/authContext';
import { TextInput } from 'common/Input';
import { ButtonsFooter, ColumnContainer } from '../Styles/TranslationsPopupContainers';
import { CustomToast } from 'common/CustomToast';
import { DefaultLanguageSelector } from 'pages/translations/DefaultLanguageSelector';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { Icon, IconType } from 'common/Icon';

const TranslationsPopup = ({
    open,
    close, 
    translationKey, 
    availableLanguages, 
    languages, 
    updateTranslation, 
    setName,
    name,
    segmentsToTranslate,
    translatedLanguages,
    loadingTranslation,
    setTranslatedLanguages 
}) => {

    const { t } = useTranslation();
    const {
		state: { selectedRestaurant },
	} = useContext(Context);
    
    const [defaultLanguage, setDefaultLanguage] = useState(selectedRestaurant[0].default_language ? selectedRestaurant[0].default_language : "spanish")
    const [translationsToUpdate, setTranslationsToUpdate] = useState({})
    const [loadingSuggestion, setLoadingSuggestion] = useState(false)

    const handleDefaultLanguageChange = async (default_language) => {
        const pk_default_language = default_language['value']
        const data = {"language": pk_default_language, "restaurant": selectedRestaurant[0].value}
        await translation.post(`/default-language`, data).then(async (response) => {
            if (response.status === 200) {
                CustomToast(t("default_language_saved"), "success")
                setDefaultLanguage(pk_default_language)
            } else {
                CustomToast(t("default_language_error"), "error")
            }
        }).catch((e) => {
            console.log(`Error in handleDefaultLanguageChange in RelatedProductsCustomGroupTranslationsPopup: ${e}`)
            CustomToast(t("default_language_error"), "error")
        })
    };

    const handleLanguageChange = (language, index) => {
        const newValue = language.value;
    
        setTranslatedLanguages(prevLanguages => {
            const updatedLanguages = [...prevLanguages];
            updatedLanguages[index] = newValue; // Reemplaza el idioma en la posición correcta
            return updatedLanguages;
        });
    };

    const addAnotherLanguage = async () => {
        if (translatedLanguages.length >= availableLanguages.length) return;
        
        const new_language = availableLanguages
          .filter(lang => lang !== defaultLanguage && !translatedLanguages.includes(lang)) 
          .find(language => language); // Simply take the first available valid language
        
        if (!new_language) return; // Prevent adding undefined
        
        const languagesToTranslate = [...translatedLanguages, new_language];
        
        setTranslatedLanguages(languagesToTranslate);
    }; 
    
    const handleSubmit = () => {    
        const mergeTranslationsAndSegments = {
            translations: {...translationsToUpdate},
            segmentsToTranslate: {...segmentsToTranslate}
        };

        updateTranslation({ custom_group_translations: [mergeTranslationsAndSegments] });
        close();
    };
    
    const getTranslationValue = (segment, colLang) => {
        if (segment && segment[colLang]) {
            return segment[colLang]["value"] || segment[colLang]["revision"] || segment[colLang]["ai"];
        }
        return ''; 
    };

    const LabelDefaultSelector = () => {
        return(
            <div className='pb-4' style={{fontWeight: 500, display: 'flex', gap: "5px", whiteSpace: 'nowrap'}}>    
                {t('language')}           
                <p style={{fontStyle: 'italic', fontWeight: 400}}>({t("by_default")})</p>
            </div>
        )
    }

    const handleRemoveLanguage = (index) => {

        const languageToRemove = translatedLanguages[index]

        setTranslationsToUpdate((prev) => ({
            ...prev,
            [translationKey]: {
                ...prev[translationKey], 
                [languageToRemove]: '',  
            },
        }));

        setTranslatedLanguages((prevLanguages) => {
            return prevLanguages.filter((_, i) => i !== index);
        });
    };

    const handleKeyPress = (e, language) => {
        if (e.keyCode === 13 || e.which === 13) {
            handleTranslationChange(e.target.value, language)
        }
    };

    const handleTranslationChange = (e, language) => {
        const value = e.target.value;

        if(language === defaultLanguage){
            setName(value)
        }

        setTranslationsToUpdate((prev) => ({
            ...prev,
            [translationKey]: {
                ...prev[translationKey], 
                [language]: value, 
            },
        }));
    };

    const getSuggestion = async (language, id) => {
        setLoadingSuggestion(true);
        const currentInputValue = document.getElementById(id)?.value
        const restaurantId = selectedRestaurant[0].value

        try {
            const res_obj = await translation.get(`/suggested-translation?text=${currentInputValue}&target=${language}&restaurant=${restaurantId}`);
            const res_data = res_obj.data;
    
            if (res_data && res_data.suggested_translation) {
                setTranslationsToUpdate((prev) => ({
                    ...prev,
                    [translationKey]: {
                        ...prev[translationKey], 
                        [language]: res_data.suggested_translation,  
                    },
                }));
            }
        } catch (error) {
            console.log(`Error in getSuggestion for IA Translations: ${error}`)
        } 
        setLoadingSuggestion(false)
    }

    return (
        <Popup isOpen={open} close={close}>
            <ColumnContainer style={{alignItems: 'center'}}>
                <header>
                    <h3>{t('add_title_and_translations')}</h3>
                </header>
                <hr></hr>
            </ColumnContainer>


            <div className='flex py-4 text-[1.125rem] items-center gap-8 pb-8'>
                <div className='language-selector-container w-full'>
                    <DefaultLanguageSelector
                        label={<LabelDefaultSelector />}
                        id={'def_lang_inp'}
                        placeholder={t('Select one option')}
                        options={languages}
                        value={languages.find(ln => ln.value === defaultLanguage)}
                        onChange={handleDefaultLanguageChange}
                        styles={default_language_selector_styles}
                    />
                </div>

                <ColumnContainer style={{position: 'relative'}}>
                    <TextInput
                        id={`${defaultLanguage}-${0}`}
                        label= {<div className='pb-4' style={{fontWeight: 500}}>{t('title')}</div>}
                        className='w-[300px]'
                        placeholder={t('placeholder_default_language_custom_group')}
                        type='text'
                        defaultValue={name || (segmentsToTranslate && segmentsToTranslate[translationKey] ? getTranslationValue(segmentsToTranslate[translationKey], defaultLanguage) : '')}
                        onBlur={(e) => handleTranslationChange(e, defaultLanguage)}
                        onKeyDown={(e) => handleKeyPress(e, defaultLanguage)}
                        onChange={(e) => null}
                        disabled={loadingTranslation }
                    />
                </ColumnContainer>

                <ColumnContainer className='pt-[35px] opacity-0 pointer-events-none'>
                    <Icon
                        type={IconType.TRASH}
                        size={20}
                        onClick={() => null}
                    />
                </ColumnContainer>
            </div>

            <div className='language-selectors_list'>
            
            {translatedLanguages && translatedLanguages.length > 0 && translatedLanguages.filter(lang => lang !== defaultLanguage).map((language, index) => (
                <div key={`availableLanguages_${index}`} className='flex py-4 text-[1.125rem] items-center gap-8 pb-8 items-center'>
                    <div className='language-selector-container'>
                        <DefaultLanguageSelector
                            label={index === 0 ? <div className='pb-4' style={{fontWeight: 500}}>{t('language')}</div> : ''}
                            id={'def_lang_inp'}
                            placeholder={t('Select one option')}
                            options={languages.filter(lang => lang.value !== defaultLanguage && !translatedLanguages.includes(lang.value))}
                            value={languages.find(ln => ln.value === translatedLanguages[index])}
                            onChange={(selectedLang) => handleLanguageChange(selectedLang, index)}
                            styles={default_language_selector_styles}
                        />
                    </div>

                    <ColumnContainer style={{position: 'relative'}}>
                        <TextInput
                            id={`${language}-${index}`}
                            className='w-[300px]'
                            label={index === 0 ? <div className='pb-4' style={{fontWeight: 500}}>{t('title')}</div> : ''}
                            placeholder={''}
                            type='text'
                            value={translationsToUpdate?.[translationKey]?.[language] ?? 
                                (segmentsToTranslate?.[translationKey] ? getTranslationValue(segmentsToTranslate[translationKey], language) : '')}                            
                            onBlur={(e) => handleTranslationChange(e, language)}
                            onKeyDown={(e) => handleKeyPress(e, language)}
                            onChange={(e) => null}
                            disabled={loadingTranslation }
                        />
                        {!loadingTranslation && !loadingSuggestion && defaultLanguage &&
                            <div  
                                onClick={() => getSuggestion(language, `${defaultLanguage}-${0}`)}
                                className={`absolute right-3 h-auto w-3 cursor-pointer self-center ${index === 0 ? 'top-[4rem]' : 'top-[1.25rem]'}`}
                            >
                                <Icon 
                                    type={IconType.AI} 
                                    color='#094553'
                                    size={15}
                                />
                            </div>    
                        }
                    </ColumnContainer>

                    <ColumnContainer className={index === 0 ? 'pt-[35px]' : ''}>
                        <Icon
                            type={IconType.TRASH}
                            size={20}
                            onClick={() => handleRemoveLanguage(index)} 
                        />
                    </ColumnContainer>
                </div>
            ))}

            {translatedLanguages.length + 1 < availableLanguages.length &&
                <Button
                    label={t(`add_new_f`)}
                    leftIcon={<PlusCircleIcon/>}
                    onClick={addAnotherLanguage}
                    variant={BUTTON_VARIANTS.SECONDARY}
                />
            }

            </div>

            <ButtonsFooter>     
                <Button 
                    size={BUTTON_SIZES.LG}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={close}
                    label='cancel'
                    className = 'mt-6'
                    disabled={loadingTranslation}
                    loading={loadingTranslation}
                />
                <Button 
                    size={BUTTON_SIZES.LG}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={handleSubmit}
                    label='save'
                    className = 'mt-6 p-2'
                    disabled={loadingTranslation}
                    loading={loadingTranslation}
                />
            </ButtonsFooter>
        </Popup>
    )
}

export default TranslationsPopup

export const default_language_selector_styles = {
    control: styles => {
      return {
        ...styles,
        height: '56px',
        width: '175px',
        borderRadius: '10px'
      };
    }
};