import { InfoTooltip } from 'common/InfoTooltip';
import { TextInput, NumberInput, TextArea, ImageInput } from 'common/Input';
import { ProductPopupMockup } from 'components/previews/components';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { calculateCents } from 'services/payments';

export const ModifierSpecs = ({
  modifierDraft,
  disabledPriceInput,
  loading,
  editModifier,
}) => {
  const { t } = useTranslation();

  const [price, setPrice] = useState(modifierDraft.tpv_price_cents ? (modifierDraft.tpv_price_cents / 100).toFixed(2) : '');

  const handleNameChange = event => {
    editModifier({ name: event.target.value });
  };
  
  const handleDescriptionChange = event => {
    editModifier({ description: event.target.value });
  };

  const handlePriceChange = event => {
    if (disabledPriceInput) return;

    const value = event.target.value;

    let priceString = value.replace(/[^\d.,]/g, '').replace(',', '.');
    priceString = priceString === '' ? '' : Number(priceString) < 0 ? '' : priceString;
    
    if (priceString.includes('.')) {
      const index = priceString.indexOf('.');
      const decimal = priceString.slice(index + 1).replace('.', '').slice(0, 2);
      priceString = priceString.slice(0, index + 1) + decimal;
    }
    
    const priceCents = Math.floor(calculateCents(priceString));
    
    setPrice(priceString);
    editModifier({ tpv_price_cents: priceCents });
  };

  const uploadImage = ({ url, file }) => {
    editModifier({ image: url, imageFile: file });
  };

  const deleteImage = () => {
    editModifier({ image: '', imageFile: null });
  };

  return (
    <div className="flex gap-4 w-full overflow-auto py-6 flex-1 h-0">
      <ProductPopupMockup product={modifierDraft}/>
      <div className='inputs'>
        {disabledPriceInput &&
          <div className="row">
            <TextInput
              name='id'
              label={t('id')}
              value={modifierDraft.id}
              className='modifier_config_item'
              disabled
            />
            <TextInput
              name='tpv_name'
              label={t('tpv_name')}
              value={modifierDraft.tpv_name}
              className='modifier_config_item'
              disabled
              />
          </div>
        }
        <div className='row'>
          <TextInput
            name='name'
            label={t('name')}
            value={modifierDraft.name || ''}
            className='modifier_config_item'
            placeholder={modifierDraft.tpv_name}
            onChange={handleNameChange}
          />
          <NumberInput
            name='price'
            label={t('Price')}
            value={price}
            className='modifier_config_item'
            disabled={disabledPriceInput}
            placeholder='0,00'
            unit={'€'}
            onChange={handlePriceChange}
          />
        </div>
        <TextArea
          name='description'
          label={
            <h6 className="flex gap-2 items-center">
              {t('Description')}
              <InfoTooltip>
                <ul style={{listStyle: 'none', width: 'max-content', padding: 0}}>
                  <Trans
                    i18nKey={'format_text_info'}
                    defaults={'<li>**bold** ➡ <b>bold</b></li><li>//italic// ➡ <i>italic</i></li><li>__underline__ ➡ <u>underline</u></li>'}
                    components={{ li: <li/>, b: <b/>, i: <i/>, u: <u/> }}
                  />
                </ul>
              </InfoTooltip>
            </h6>
          }
          value={modifierDraft.description || modifierDraft.translated_description || modifierDraft.tpv_description || ""}
          placeholder={t('Description')}
          onChange={handleDescriptionChange}
        />
        <div className="row">
          <ImageInput
            image={modifierDraft.image}
            disabled={loading}
            onUpload={uploadImage}
            onDelete={deleteImage}
          />
        </div>
        <p className="text-base">
          <Trans i18nKey={'img_suggestions'}>
            <b className="font-semibold">Sugerencias:</b> png/jpg ou gif 400x400 pixels, entre 1MB et 2MB.
          </Trans>
        </p>
      </div>
    </div>
  );
};
