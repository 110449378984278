import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput } from 'common/Input';
import styled from 'styled-components';
import { components }  from 'react-select';
import { Icon } from 'common/Icon';
import './Translations.css'


export const DefaultLanguageSelector = ({id, placeholder, options, value, onChange, styles, label }) => {
  return (
    <SelectInput
      label={label}
      id={id}
      placeholder={placeholder}
      options={options}
      value={value}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      onChange={onChange}
      styles={styles}
      components={{ MultiValue, Option, ValueContainer }}
    />
  );
};

const MultiValue = (props) => {
  const { t } = useTranslation();
  return (
    <components.MultiValue {...props}>
      <ValueContainerContainer>
        {t('languages')}
      </ValueContainerContainer>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let tmpChildren = [...children];
  return (
    <components.ValueContainer {...props}>
      <ValueContainerContainer>
        {<Icon type={tmpChildren[0].props.data?.value.toUpperCase()} size={20}/>}
        {tmpChildren}
      </ValueContainerContainer>
    </components.ValueContainer>
  );
};


const Option = (props) => {
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <OptionContainer color={props.data.color}>
          <SelectorContainer color={props.data.color}>
            {<Icon type={props.data.value.toUpperCase()}/>}
          </SelectorContainer>
          {t(props.data.label)}
      </OptionContainer>
    </components.Option>
  );
};

export const SelectorContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  color: ${({color}) => color};
  background: #FFF;
  border-radius: 2rem;
  overflow: hidden;

  svg {
    height: 100%;
    width: 100%;
    background-color: ${({color}) => color + 40};
  }
`;

export const OptionContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  white-space: nowrap;
`;

export const ValueContainerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #094553;
  font-weight: 600;
`;

export const language_selector_styles = {
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      margin: 0,
    };
  },
  control: styles => {
    return {
      ...styles,
      height: '40px',
      width: '200px',
      borderRadius: '50px',
      color: "#094553",
      borderColor: "#094553"
    };
  },
  option: (styles) => {
    return {
      ...styles,
      color: "#000000",
      backgroundColor: "#FFFFFF"
    };
  },
};
