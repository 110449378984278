import React from 'react';
import { ProgressBarMockup } from '../components/ProgressBarMockup';
import styled from 'styled-components';
import { FIELDS } from 'pages/admin/pages/OrderAndPay/Modals/NutritionalInfo';
import { useTranslation } from 'react-i18next';
import { TabSelector } from 'common/TabSelector';
import { calculateCalories } from 'logic/functions';
import { FormatedText } from 'common/FormatedText';

export const NutritionalValuesPreview = ({ item, theme }) => {
  const { t } = useTranslation();

  const calories = calculateCalories(item);
  const servingSize = (!item.serving_size && item.serving_size !== 0) ? null : item.serving_size;

  return (
    <NutritionalValuesPreviewContainer>
      <div className='sticky'>
        <h5>{item.visible_name}</h5>
        <TabSelector
          className='scale_down'
          options={['product_details', 'nutritional_table']}
          value='nutritional_table'
          breadcrumb={false}
        />
      </div>
      {item?.ingredients &&
        <p>
          {t('ingredients') + ': '}<br/>
          <FormatedText text={item.ingredients}/>
        </p>
      }
      <div className='energy_serving'>
        <div>
          <h6>{t('energy')}</h6>
          <span>{calories === null ? t('not_specified') : calories + 'kcal'}</span>
        </div>
        <div>
          <h6>{t('serving_size')}</h6>
          <span>{servingSize === null ? t('not_specified') : servingSize + (item.serving_measurement_unit || 'g')}</span>
        </div>
      </div>
      {FIELDS.map(field =>
        <Bar key={field} item={item} field={field} theme={theme} t={t}/>
      )}
    </NutritionalValuesPreviewContainer>
  );
};

const Bar = ({ item, field, theme, t }) => {
  if (!item[field] && item[field] !== 0) return <></>;

  const totalGrams = (Number(item[field] || 0) / 100 * (Number(item.serving_size) || 100))
    .toFixed(2).replace(/^(\d+)(?:\.0+|(\.\d*?)0+)$/, '$1$2'); // remove trailing zeros

  const suffix = '/100' + (item.serving_measurement_unit || 'g');

  return (
    <div>
      <div className='bar_title'>
        <h6>{t(field.replace("_string", "")) + (item.serving_size ? '' : suffix)}</h6>
        <span>{totalGrams}g</span>
      </div>
      <ProgressBarMockup
        current={Number(item[field])}
        total={100}
        theme={theme}
        animated
      />
    </div>
  );
};

const NutritionalValuesPreviewContainer = styled.div`
  width: 300px;
  height: fit-content;
  max-height: 100%;
  border: 1px solid #D4D4D440;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 4px #0000001A;
  padding: 24px;
  overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
  
	&::-webkit-scrollbar {
		width: 0;
	}

  .sticky {
    position: sticky;
    top: -24px;
    background-color: #FFF;
    padding-top: 24px;
    margin-top: -24px;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
  }
  
  .scale_down {
    transform: scale(0.65);
    transform-origin: left;
    position: sticky;
    top: 14px;
  }

  p, h6 {
    font-size: 10px;
    margin-top: 8px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  h6 {
    font-weight: 600;
  }

  p {
    white-space: pre-wrap;
  }

  & > span {
    font-size: 14px;
    line-height: 1;
  }

  .energy_serving {
    display: flex;
    justify-content: space-around;
    text-align: center;

    span {
      display: inline-block;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .bar_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: 10px;
      font-weight: 600;
      line-height: 2.2;
    }
  }
`;