import React, { useState, useEffect, useContext } from "react";
import { ObjectTypes, patchObject, saveGroup } from "../api-OrderAndPay";
import { Context as AuthContext } from "../../../../../data/authContext";
import VisibilitySchedulePopup from "../VisibilitySchedulePopup";
import { TabSelector } from "common/TabSelector";
import { isEqual, cloneDeep } from "lodash";
import { ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/outline";
import { deleteObject } from "../api-OrderAndPay";
import { ProductSpecs } from "../ProductSpecs";
import { RelatedProducts } from "../RelatedProducts";
import ProductSchedules from "../ProductSchedules";
import { TPVS } from "../../DragAndDrop/constants";
import { CustomProgressBar } from "common/CustomProgressBar";
import { AllergensAndTags } from "../AllergensAndTags";
import { BUTTON_COLORS, BUTTON_VARIANTS, Button } from "common/Button";
import { ConfirmPopup } from "../../Lockers/ConfirmPopup";
import { NUTRITIONAL_FIELDS, NutritionalInfo } from "./NutritionalInfo";
import { Popup } from "common/Popup";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActiveSwitch } from "../ActiveSwitch";
import { DeletePopup } from "../../Modifiers/DeletePopup";
import { useRelatedProducts } from "../../useRelatedProducts";
import { CustomToast } from 'common/CustomToast';
import { logToServer } from "logic/functions";
import { toast } from "react-toastify";
import { translation } from "logic/api";

const VISIBILITY_ENDPOINTS = {
  SUPER_GROUP: "supergroup",
  CATEGORY_GROUP: "category_group",
  CATEGORY: "product_category",
  PRODUCT: "product",
};

const NEW_PRODUCT = { name: "", visibility: 1, allergens: [], tags: [], tpv_price_cents: 0, serving_measurement_unit: 'g', custom_groups: [] };

const TABS = ["specs", "allergens_and_tags", "nutritional_table", "schedules", "related"];

export const EditProductModal = ({
  productToEdit,
  parentId,
  categoriesOptions,
  tpv,
  isTspoonlab,
  closeModal,
  mutate
}) => {
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();
  const isYumminnProduct = tpv.includes(TPVS.YUMMINN) || selectedRestaurant[0].custom_catalog;
  const [tab, setTab] = useState(TABS[0]);

  const newProductToEdit = {
    ...productToEdit, 
    proteins: productToEdit?.proteins_string, 
    fats: productToEdit?.fats_string,
    saturated_fats: productToEdit?.saturated_fats_string,
    carbohydrates: productToEdit?.carbohydrates_string,
    sugars: productToEdit?.sugars_string,
    sodium: productToEdit?.sodium_string,
    serving_size: productToEdit?.serving_size_string,
    fiber: productToEdit?.fiber_string
  }

  const [productDraft, setProductDraft] = useState( newProductToEdit || NEW_PRODUCT);
  const [category, setCategory] = useState(categoriesOptions.find(cat => cat.value === parentId))

  const [scheduleToEdit, setScheduleToEdit] = useState(null);
  const [schedulesToDelete, setSchedulesToDelete] = useState([]);
  const [timeRangesToDelete, setTimeRangesToDelete] = useState([]);

  const [skipSchedules, setSkipSchedules] = useState(!!productToEdit);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [products, setProducts] = useState([]);

  const relatedCustomGroup = {...productDraft}?.custom_groups?.[0]

  const newTranslation = async (translatedSegment, translationMethod, language, customGroupId) => {
    try {
        const method = translationMethod === 'new' ? 'post' : translationMethod === 'edit' ? 'patch' : 'delete'
        const seleccionado = JSON.parse(localStorage.getItem('yumminn-selectedRestaurant')) || selectedRestaurant
        let restaurant = null
        if (seleccionado) {
            if (Array.isArray(seleccionado) && seleccionado[0].label !== 'Todos') {
                restaurant = seleccionado[0]
            } else if (!Array.isArray(seleccionado) && seleccionado && seleccionado.label !== 'Todos') {
                restaurant = seleccionado
            }
        }
        if (restaurant && !loading) {
            let restaurantId = restaurant.value
            const body = {
                'model': 'CustomGroupNameTranslation',
                'id': customGroupId,
                'language': language,
                'value': translatedSegment,
                'restaurant': restaurantId,
                'revision': false
            }
            
            if (((method === 'post' || method === 'patch') || method === 'delete')) {
                setLoading(true);
                await translation[method]("/translation", method === 'delete' ? { data: body } : body).then(() => {
                    setLoading(false);
                })
            }
        }

    } catch (e) {
        toast.error(t('an_error_has_occurred'));
        logToServer('error updating translation', selectedRestaurant?.id)
        setLoading(false)
    }
  }

  const translationUpdate = async (e, segment, language, customGroup) => {
    let translationMethod = "new";
    const segment_type = "value";
    const customGroupId = relatedCustomGroup?.id || customGroup

    // Add new translation if not already there
    if ((!segment || (!segment[language] || !segment[language][segment_type])) && e) {
      translationMethod = "new";
      await newTranslation(e, translationMethod, language, customGroupId);
    }

    // Check if a translation already exists and it's different
    else if (e && e.length > 0 && segment[language] && segment[language][segment_type] && e !== segment[language][segment_type]) {
        translationMethod = "edit"; // Editing an existing translation
        await newTranslation(e, translationMethod, language, customGroupId);
    } 
    // Handle deletion of a translation
    else if (!e && segment[language] && segment[language][segment_type]) {
        translationMethod = "delete";
        await newTranslation(e, translationMethod, language, customGroupId);
    } 
   
    
  }

  const editProduct = (object) => {
    if (object?.name !== undefined) object.visible_name = object.name;
    setProductDraft((prevGroup) => ({ ...prevGroup, ...object }));
  };

  const { updateRelatedProducts } = useRelatedProducts();

  const openSchedulePopup = () => {
    setTab("edit_schedule");
  };

  const closeSchedulePopup = () => {
    setTab("schedules");
    setScheduleToEdit(null);
  };

  const newSchedule = () => {
    setScheduleToEdit(null);
    openSchedulePopup();
  };

  const editSchedule = (schedule) => {
    setScheduleToEdit(schedule);
    openSchedulePopup();
  };

  const defineSchedule = (schedule) => {

    const existingSchedules = [...(productDraft?.visibility_schedules || [])];

    const index = existingSchedules.findIndex(
      (item) =>
        (schedule.id && item.id === schedule.id) ||
        (schedule.tempId && item.tempId === schedule.tempId)
    );

    if (index === -1) {
      existingSchedules.push(schedule);
    } else {
      existingSchedules[index] = schedule;
    }

    editProduct({ visibility_schedules: existingSchedules });

    closeSchedulePopup();
  };

  const next = () => setTab(prev => TABS[Math.min(TABS.indexOf(prev) + 1, TABS.length)]);

  const save = async () => {
    
    if (isSaveDisabled || !selectedRestaurant) return;

		setLoading(true);
		setSaving(!productToEdit);

    const groupType = 'PRODUCT'
    const endpoint = VISIBILITY_ENDPOINTS[groupType];
    const restaurant = selectedRestaurant[0].value;

    const newProduct = {...productDraft};

    if ('tpv_price_cents' in newProduct && !isYumminnProduct) {
      delete newProduct.tpv_price_cents;
    }

    if ('custom_groups' in newProduct && Object.keys(newProduct['custom_groups']).length > 0) {
      const product = productDraft.id

      const customGroupId = await updateRelatedProducts(
        relatedCustomGroup,
        products, 
        product,
        editProduct, 
        setProducts
      )
     
      if ('custom_group_translations' in newProduct && Object.keys(newProduct['custom_group_translations']).length > 0) { 
        const translations = newProduct['custom_group_translations'][0].translations;
        const segmentsToTranslate = newProduct['custom_group_translations'][0].segmentsToTranslate;
        
        for (const segmentId in translations) {
          const validSegmentId = segmentId === undefined ? customGroupId : segmentId;

          let segment = segmentsToTranslate[validSegmentId];

          if (!segment && validSegmentId) {
            segment = validSegmentId; //assing customGroup Id if new translation
          }
  
          if (segment) {
            const segmentTranslations = translations[segmentId];
            for (const language in segmentTranslations) {

              const translationValue = segmentTranslations[language];

              await translationUpdate(translationValue, segment, language, customGroupId);
            }
          }
        }
      }
    
    }

    if (!newProduct.serving_measurement_unit) {
      newProduct.serving_measurement_unit = 'g';
    }

    NUTRITIONAL_FIELDS.forEach(field => {
      if (newProduct[field] || newProduct[field] === 0) {
        newProduct[field] = Number(newProduct[field]) || 0;
      } else {
        newProduct[field] = null;
      }
    });

    try {
      const id = await saveGroup({
        endpoint: endpoint,
        group: newProduct,
        restaurant: restaurant,
        groupType: groupType,
        parentId: category.value,
        groupToEdit: productToEdit,
        schedulesToDelete: schedulesToDelete,
        timeRangesToDelete: timeRangesToDelete,
        t: t
      });

      sessionStorage.setItem('lastHighlightedRow', id);

      await mutate();

      //productToEdit && (productToEdit.allergens = allergensFinal);
      closeModal();
    } catch (error) {
      setLoading(false);
      setSaving(false);
      console.log("🚀 ~ file: CreateEditGroupModal.jsx:128 ~ save ~ error:", error);
    }
	}

  const cancel = () => (hasChanges ? setShowConfirmCancel(true) : closeModal());

  const deleteProduct = () => isYumminnProduct && setShowConfirmDelete(true);

  const confirmDelete = async () => {
    if (!isYumminnProduct) return;

    try {
      setLoading(true);
      await deleteObject({ objectType: ObjectTypes["PRODUCT"], object: productDraft, t: t });
      sessionStorage.setItem('lastHighlightedRow', '');
      await mutate();
      closeModal();
    } catch (error) {
      console.log(
        "🚀 ~ file: EditProductModal.jsx:339 ~ delete ~ error:",
        error
      );
      setLoading(false);
    }
  };

  const toggleVisibility = async () => {
    if (loading) return;

    const hasSchedules = productDraft?.visibility_schedules?.length > 0;
    const newVisibility = productDraft.visibility === 0 ? (hasSchedules ? 2 : 1) : 0;

    if (!productToEdit) {
      editProduct({ visibility: newVisibility });
      return;
    }

    setLoading(true);

    const object = {
      id: productDraft.id,
      visibility: newVisibility,
    };

    try {
      const res = await patchObject({ objectType: ObjectTypes.PRODUCT, object, t });
      res.status === 200 && editProduct({ visibility: newVisibility });
      productToEdit.visibility = newVisibility;
      setLoading(false);
    } catch (error) {
      console.log(
        "🚀 ~ file: EditProductModal.jsx:48 ~ toggleVisibility ~ error:",
        error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productDraft.visibility === 0) return;

    const newVisibility =
      productDraft?.visibility_schedules && productDraft?.visibility_schedules.length > 0
        ? 2
        : 1;
    editProduct({ visibility: newVisibility });
  }, [productDraft?.visibility_schedules]);

  useEffect(() => {
    tab === "schedules" && !skipSchedules && setSkipSchedules(true);
  }, [tab]);

  useEffect(() => {
    const groupCopy = cloneDeep(productDraft);
    const groupToEditCopy = cloneDeep(productToEdit || NEW_PRODUCT);

    groupCopy.visibility = null;
    groupToEditCopy.visibility = null;

    const hasNewChanges = !isEqual(groupCopy, groupToEditCopy) || category.value !== parentId;
    const hasName = !!(
      productDraft.name ||
      productDraft.visible_name ||
      productDraft.tpv_name
    );
    const hasValidPrice = (!tpv.includes(TPVS.YUMMINN) && !selectedRestaurant[0].custom_catalog) || (
      typeof productDraft.tpv_price_cents === 'number' &&
      !isNaN(productDraft.tpv_price_cents) &&
      productDraft.tpv_price_cents >= 0
    );

    setHasChanges(hasNewChanges);
    setIsSaveDisabled(!hasName || !hasNewChanges || !hasValidPrice);
  }, [productDraft, category]);

  return (
    <ProductPopup isOpen close={closeModal} tab={tab}>
      <header>
        <h2>
          {t(productToEdit ? `Edit_product` : `Create_product`)}
        </h2>
        <ActiveSwitch
          checked={productDraft.visibility !== 0}
          disabled={loading}
          onChange={toggleVisibility}
        />
      </header>

      <TabSelector
        name={"edit_group"}
        value={tab !== "edit_schedule" ? tab : "schedules"}
        options={TABS}
        onChange={setTab}
        className='flex flex-1 w-0 flex-row items-center gap-3 overflow-x-auto overflow-y-hidden scrollbar-thin'
        scrollWhenOverflow
      />

      {tab === "specs" && (
        <ProductSpecs
          productDraft={productDraft}
          isYumminnProduct={isYumminnProduct}
          loading={loading}
          isSaveDisabled={isSaveDisabled}
          category={category}
          categoriesOptions={categoriesOptions}
          handleCategoryChange={value => setCategory(value)}
          editProduct={editProduct}
          cancel={cancel}
          save={save}
        />
      )}
      {tab === "related" && (
        <RelatedProducts
          productDraft={productDraft}
          loading={loading}
          isSaveDisabled={isSaveDisabled}
          setProducts={setProducts}
          products={products}
          editProduct={editProduct}
        />
      )}
      {tab === "allergens_and_tags" &&
        <AllergensAndTags
          draft={productDraft}
          loading={loading}
          editDraft={editProduct}
        />
      }
      {tab === "schedules" && (
        <ProductSchedules
          product={productDraft}
          editProduct={editProduct}
          save={save}
          newSchedule={newSchedule}
          editSchedule={editSchedule}
          setSchedulesToDelete={setSchedulesToDelete}
        />
      )}
      {tab === "edit_schedule" && (
        <VisibilitySchedulePopup
          scheduleToEdit={scheduleToEdit}
          define={defineSchedule}
          cancel={closeSchedulePopup}
          setTimeRangesToDelete={setTimeRangesToDelete}
          schedulesLength={productDraft?.visibility_schedules?.length || 0}
          groupType={"product"}
        />
      )}
      {tab === 'nutritional_table' &&
        <NutritionalInfo
          item={productDraft}
          editItem={editProduct}
          isTspoonlab={isTspoonlab}
        />
      }
      {tab !== 'edit_schedule' &&
        <footer className={`flex flex-row justify-between items-center gap-4`}>
          {isYumminnProduct && productToEdit &&
            <Button
              label={<TrashIcon/>}
              onClick={deleteProduct}
              disabled={loading}
              variant={BUTTON_VARIANTS.SECONDARY}
              color={BUTTON_COLORS.DANGER}
            />
          }
          <div className={`flex flex-row justify-end items-center gap-4 ml-auto`}>
            <Button
              label="cancel"
              onClick={cancel}
              disabled={loading}
              variant={BUTTON_VARIANTS.SECONDARY}
            />
            <Button
              label={skipSchedules ? (productDraft ? "save" : "create") : "next"}
              loading={loading}
              disabled={isSaveDisabled}
              onClick={skipSchedules ? save : next}
            />
          </div>
        </footer>
      }
      <ConfirmPopup
        isOpen={showConfirmCancel}
        title='you_have_unsaved_changes'
        message='warning_message_unsaved_changes'
        cancelLabel='keep_editing'
        confirmLabel='close'
        cancel={() => setShowConfirmCancel(false)}
        confirm={closeModal}
      />
      <DeletePopup
        title='remove_product'
        message='are_you_sure_to_remove_product'
        isOpen={showConfirmDelete}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmDelete(false)}
      />
      <Popup isOpen={saving}>
        <div className="flex flex-col gap-6 max-w-xs">
          <h4 className="text-xl font-semibold">
            {t('creating_product_title') + '✨'}
          </h4>
          <CustomProgressBar estimatedSeconds={5}/>
          <i>
            {t('creating_product_message') + '🎉'}
          </i>
        </div>
      </Popup>
    </ProductPopup>
  );
};

const ProductPopup = styled(Popup)`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: ${({tab}) => tab = 'related' ? 'hidden' : 'auto'};
    max-height: 100%;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px; 

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.3;
      margin: 0;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    border-top: 1.5px solid #DDDDDD;
    padding-top: 24px;
  }
`