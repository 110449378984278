import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { baseAuth } from "logic/api";
import Spinner from "common/Spinner";
import { toast } from 'react-toastify';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Context as AuthContext } from '../../../data/authContext';
import orderAndPayLogo from "../../../img/o&p.svg"
import { components } from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { XIcon } from '@heroicons/react/outline';
import { MobileMockup } from "components/previews";
import { SuggestionsPreview } from "components/previews/screens/SuggestionsPreview";
import QRCode from "react-qr-code";
import { ActiveSwitch } from "./OrderAndPay/ActiveSwitch";
import { InfoTooltip } from "common/InfoTooltip";
import { SelectInput } from "common/Input";
import { Icon, IconType } from "common/Icon";

const mapCategoryOptions = categories => {
	if (!categories) return [];

	return categories.filter(category => category.products !== undefined).map(category => ({
		"label": category.name ? category.name : category.tpv_name,
		"options": mapProductOptions(category.products),
	}));
};

const mapProductOptions = products => {
  return products.map((product) => ({
			"label": product.product_name || product.name || product.product_tpv_name || product.tpv_name,
			"value": product.product_id || product.id,
			"product_image": product.product_image || product.image || "",
			"product_name": product.product_name || product.name || "",
			"product_tpv_name": product.product_tpv_name || product.tpv_name || "",
			"product_tpv_price_cents": product.product_tpv_price_cents || product.tpv_price_cents,
			"product_id": product.product_id || product.id,
	}));
};

export const Suggestions = () => {
	const {
		state: { selectedRestaurant }
	} = React.useContext(AuthContext);

	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [suggestionGroup, setSuggestionGroup] = useState(null);
	const [catalog, setCatalog] = useState(null);
	const [products, setProducts] = useState([]);
	const [testUrl, setTestUrl] = useState(null);
	const has_to_update = (JSON.stringify(products.map(pr => ({id: pr.product_id, order: pr.order }))) !== JSON.stringify(suggestionGroup?.products_info.map(pr_i => ({id: pr_i.product_id, order: pr_i.order })))) && products.length > 3
	
	useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'))

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true)
		}
		setSuggestionGroup(null)
		setProducts([])
		setCatalog(null)
		setTestUrl(null)
		if (selectedRestaurant && selectedRestaurant.length > 0) {
			getData();
		}
	}, [selectedRestaurant])

	const getData = async () => {
		setLoading(true)
		await baseAuth.get(`/custom_group/suggestions_and_related_products?restaurant=${selectedRestaurant[0].value}`)
			.then((response) => {
				const suggestion_group = response.data.suggestions
				const catalog = response.data.catalog
				const test_url = response.data.test_table_url
				const suggestion_group_products = suggestion_group?.products_info?.sort((a,b) => a.order - b.order);
				
				setSuggestionGroup(suggestion_group)
				setCatalog(catalog)
				setTestUrl(test_url)
				setProducts(suggestion_group_products || [])
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

	const handleDragEnd = ({ destination, source }) => {
		const result = [...products];
		const [removed] = result.splice(source.index, 1);
		result.splice(destination.index, 0, removed);
		let newProductList = result.map((product, index) => ({ ...product, order: index }))
		setProducts(newProductList)
	}
	
	const removeProduct = (product) => {
		const result = [...products];
		const index = result.indexOf(product);
		result.splice(index, 1);
		let newProductList = result.map((product, index) => ({ ...product, order: index }))
		setProducts(newProductList)
	}

	const handleChangeOption = (value) => {
		setProducts(value.map((product, index) => {
			const newProduct = {...product};
			delete newProduct.value;
			delete newProduct.label;

			return { ...newProduct, order: index };
		}));
	};

	const handleUpdate = async (only_state) => {
		setLoading(true)
		const obj = {
			products: products,
			active: suggestionGroup ? only_state ? !suggestionGroup.active : suggestionGroup.active : true,
			restaurant: selectedRestaurant[0].value,
			only_state: only_state
		}
		await baseAuth.post(`/custom_group/update_suggestion_group`, obj)
			.then((response) => {
				const suggestion_group = response.data.suggestions
				const suggestion_group_products = suggestion_group.products_info?.sort((a,b) => a.order - b.order);
				setSuggestionGroup(suggestion_group)
				if (!only_state) {
					setProducts(suggestion_group_products)
				}
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}

	const selectorOptions = mapCategoryOptions(catalog?.product_categories);

	return (
		<>
			{(loading) &&
				<>
					<div className="h-full w-full top-0 left-0 absolute z-9999 flex flex-col justify-center items-center">
						<Spinner>{t("Loading")}</Spinner>
					</div>
					<div className="bg-black opacity-20 top-0 left-0 fixed overflow-y-auto h-full w-full z-9999 flex flex-col justify-center items-center"/>
				</>
			}
			<div style={{marginBottom: '20px', marginTop: "20px", backgroundColor: '#FFFFFF', width:  (!catalog || (!catalog?.supergroups?.length && !catalog?.product_categories?.length))  ? '100%' : '60rem', borderRadius: "20px", padding: "35px"}}>
				{(!loading && catalog && catalog.product_categories.length > 0) &&
					<div style={{display: 'flex', gap: "50px"}}>
						<div style={{width: '25rem'}}>
							<div style={{fontSize: '16px', fontWeight: 600}}>
								{t('cross_sell_title')}
							</div>
							<div style={{marginLeft: "15px", marginTop: "15px"}}>
								<ActiveSwitch
									checked={suggestionGroup ? suggestionGroup.active : true}
									loading={loading}
									onChange={() => handleUpdate(true)}
								/>
								<div style={{fontSize: '14px', marginTop: "15px", display: 'flex', gap: '8px', alignItems: 'center'}}>
									{t('select_products')}
									<InfoTooltip info='info_cross_sell_products'/>
								</div>
								<div style={{marginTop: "15px", fontSize: "13px"}}>
									<SelectInput
										id={'children'}
										name={'cross_sell_products'}
										isMulti
										isClearable={false}
										hideSelectedOptions={false}
										closeMenuOnSelect={false}
										value={mapProductOptions(products)}
										options={selectorOptions}
										className={'w-80'}
										onChange={handleChangeOption}
										components={{Option}}
									/>
								</div>
								<div style={{fontSize: '13px', marginTop: '15px'}}>
									{t(products.length < 4 ? 'at_least_four_products' : 'order_products_dnd')}
								</div>
									<div style={{marginTop: '15px', height: "250px", overflow: 'auto', width: '20rem'}}>
										<DragDropContext onDragEnd={handleDragEnd}>
											<Droppable droppableId="droppable" direction="vertical" style={{ width: '100%' }}>
												{(providedList) =>
													<div
													ref={providedList.innerRef}
													style={{position: 'relative'}}
													{...providedList.droppableProps}
													>
														{products && products.map((product, index) =>
															<Draggable key={product.product_id} index={index} draggableId={product.product_id.toString()}>
																{(provided) => (
																	<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px 0 5px 10px', fontSize: '14px', marginBottom: '10px', borderRadius: '5px', backgroundColor: '#F8F8F8', ...provided.draggableProps.style}}>
																		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', userSelect: 'none', fontWeight: 500}}>
																			<div className="min-w-[17px] min-h-[13px]">
																				<Icon type={IconType.DRAG}/>
																			</div>
																			{product.product_name || product.product_tpv_name}
																		</div>
																		<button style={{margin: "0 15px"}} onClick={() => removeProduct(product)}>
																			<XIcon className={`w-[15px] text-gray-700 cursor-pointer`}/>
																		</button>
																	</div>
																)}
															</Draggable>
														)}
														{providedList.placeholder}
													</div>
												}
											</Droppable>
										</DragDropContext>
									</div>
									<div style={{width: '15rem', display: 'flex', justifyContent: 'center'}}>
										<button
											style={{width: '225px', backgroundColor: !has_to_update ? 'gray' : '#5FB894'}}
											className={"rounded-full cursor-pointer py-2 px-4 m-2 bg-principal text-xs text-white font-bold"}
											onClick={() => has_to_update ? handleUpdate(false) : ""}
										>
											{t(suggestionGroup ? 'update' : 'save')}
										</button>
									</div>
							</div>
						</div>
						<div style={{height: 'auto', width: '15rem', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '15px'}}>
							<MobileMockup>
								<SuggestionsPreview products={products}/>
							</MobileMockup>
							<div style={{width: '90%', textAlign: 'center'}}>
								<Trans i18nKey='client_sees_products_before_payment'>
									El comensal visualizará la venta cruzada justo <b>antes de completar su cesta</b>
								</Trans>
							</div>
						</div>
						{suggestionGroup && testUrl &&
						<div style={{height: 'auto', width: '10rem', paddingRight: '15px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '15px'}}>
							<div style={{width: '90%', textAlign: 'center'}}>
								<Trans i18nKey='see_in_app'>
									<b>Visualizar</b> en la webapp
								</Trans>
							</div>
							<QRCode
							size={130}
							value={testUrl}
							/>
						</div>}
					</div>
				}
				{(!catalog || (!catalog?.supergroups?.length && !catalog?.product_categories?.length)) &&
					<div className=" bg-white rounded-xl h-36 flex gap-4 sm:flex-row font-semibold flex-col-reverse items-center justify-center ">
						<img src={orderAndPayLogo} alt="Order and Pay"></img>
						<div style={{textAlign: 'center'}}>
							<Trans i18nKey='cross_sell_no_products'>
								No se ha encontrado ningún producto para poder realizar venta cruzada.<br/>
								Debe sincronizar la carta.
							</Trans>
						</div>
					</div>
				}
			</div>
		</>
	)
}

const Option = (props) => (
	<components.Option {...props}>
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
			<label>{props.label}</label>
			<input
				className="checkboxRestaurantSelect"
				type="checkbox"
				checked={props.isSelected}
				onChange={() => null()}
			/>
			<span/>
		</div>
	</components.Option>
)

export default Suggestions