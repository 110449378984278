import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { baseAuth } from "logic/api";
import { SelectInput} from "common/Input";
import { ProductPopupMockupRelated } from "components/previews/components/ProductPopupMockupRelated";
import { Context as AuthContext } from '../../../../data/authContext';
import { components } from 'react-select';
import Spinner from "common/Spinner";
import { ChipWithToggle } from "common/ChipWithToggle";
import { InfoTooltip } from "common/InfoTooltip";
import { Icon, IconType, SquareIcon } from "common/Icon";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RelatedProductsContainer } from "./Styles/TranslationsPopupContainers";
import { useViewport } from "data/ViewportContext";
import LanguageSelectorWithPopup from "common/Input/LanguageSelectorWithPopup";
import { useRelatedProducts } from "../useRelatedProducts";

export const RelatedProducts = ({
  productDraft,
  setProducts, 
  products,
  loading, 
  isSaveDisabled,
  editProduct
}) => {
  const {
    state: { selectedRestaurant }
  } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { isMobile, isTablet } = useViewport();
  const {useTranslationsRelatedProducts} = useRelatedProducts();

  const defaultLanguage = selectedRestaurant[0].default_language ? selectedRestaurant[0].default_language : "spanish"
  const newProduct = {...productDraft};
  const customGroup = newProduct?.custom_groups?.[0] || []
  
  const { segmentsToTranslate, translatedLanguages, loadingTranslation, setTranslatedLanguages } = useTranslationsRelatedProducts(customGroup?.id, selectedRestaurant, defaultLanguage);

  const [name, setName] = useState(customGroup.visible_name || customGroup.name || '');
  const [productsWithCompleteInfo, setProductsWithCompleteInfo] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [catalog, setCatalog] = useState(null);
  const [languages, setLanguages] = useState([])
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [translationsDone, setTranslationsDone] = useState([])

  useEffect(() => {
    setProducts([])
    setCatalog(null)
    if (selectedRestaurant && selectedRestaurant.length > 0) {
      getData();
    }
  }, [selectedRestaurant])

  const getData = async () => {
    setLoadingData(true)
    await baseAuth.get(`/custom_group/suggestions_and_related_products?restaurant=${selectedRestaurant[0].value}&product=${productDraft.id}`)
      .then((response) => {
        const related = response.data.related
        const catalog = response.data.catalog
        const related_products = related?.products_info?.sort((a,b) => a.order - b.order);
        const languages_response = response.data.languages;
        const all_languages = languages_response.map((val) => ({
            label: t(val),
            value: val,
        }))
        setLanguages(all_languages)
        setTranslationsDone(related?.translated_languages)
        setAvailableLanguages(response.data.restaurant_languages)
        setCatalog(catalog)
        setProducts(related_products || [])
        const allProducts = catalog?.product_categories
        ?.filter(category => category?.products && category.products.length > 0)
        .flatMap(category => 
          category.products.map(product => ({
            ...product,
            categoryId: category.id,
            categoryName: category.name
          }))
        );
        setProductsWithCompleteInfo(allProducts)
        setLoadingData(false)
      })
      .catch((e) => {
        console.log(`error in getData in RelatedProducts:${e}`)
        setLoadingData(false)
      })
  }

  const handleChangeOption = (value) => {
    const newProducts = value.map((product, index) => {
			const newProduct = {...product};
			delete newProduct.value;
			delete newProduct.label;

			return { ...newProduct, order: index }
    })
		setProducts(newProducts);

    const newCustomGroup = {...customGroup}
    newCustomGroup.products_info = newProducts
    if (!("active" in newCustomGroup)){
      newCustomGroup.active = true
    }
    editProduct({ custom_groups: [newCustomGroup] });
	};
  
  const mapProductOptions = products => {
    return products.map((product) => ({
        "label": product.product_name || product.name || product.product_tpv_name || product.tpv_name,
        "value": product.product_id || product.id,
        "product_image": product.product_image || product.image || "",
        "product_name": product.product_name || product.name || "",
        "product_tpv_name": product.product_tpv_name || product.tpv_name || "",
        "product_tpv_price_cents": product.product_tpv_price_cents || product.tpv_price_cents,
        "product_id": product.product_id || product.id,
    }));
  };

  const mapCategoryOptions = categories => {
    if (!categories) return [];
  
    return categories.filter(category => category.products !== undefined).map(category => ({
      "label": category.name ? category.name : category.tpv_name,
      "options": mapProductOptions(category.products),
    }));
  };

  const selectorOptions = mapCategoryOptions(catalog?.product_categories);

  const toggleActive = (value) => {
    const newCustomGroup = { ...customGroup, active: value }; 
    editProduct({ custom_groups: [newCustomGroup] }); 
  };

  const changeOrder = ({ source, destination }) => {
    const orderedProducts = [...products];
    const [productMoved] = orderedProducts.splice(source.index, 1);

    orderedProducts.splice(destination.index, 0, productMoved);
    orderedProducts.forEach((product, index) => product.order = index);

    setProducts(orderedProducts);

    const newCustomGroup = { ...customGroup, products_info: orderedProducts }; 

    editProduct({ custom_groups: [newCustomGroup] });
  };

  const removeProduct = id => {
    const orderedProducts = [...products];
    const index = orderedProducts.findIndex(p => p.product_id === id);

    orderedProducts.splice(index, 1);
    orderedProducts.forEach((product, index) => product.order = index);
    
    const newCustomGroup = { ...customGroup, products_info: orderedProducts }; 

    editProduct({ custom_groups: [newCustomGroup] });
    setProducts(orderedProducts)
  };

  if(loadingData || loading || isSaveDisabled){
    return(
      <Spinner className='height'/>
    )
  }

  const inputValue = name || customGroup?.visible_name || productDraft.name || '';
  const inputPlaceholder = name || customGroup?.visible_name || productDraft.name || 'Ej. Maridaje';

  return (
    <RelatedProductsContainer className="flex gap-4 w-full overflow-auto pt-6">
      <ProductPopupMockupRelated name={name} product={productDraft} relatedProducts={products} productsWithCompleteInfo={productsWithCompleteInfo}/>
      <div className='flex flex-col gap-2 w-full pb-[2rem] max-w-[30rem] h-[438px]'>

        <div className={`${isMobile || isTablet ? 'flex-col items-start' : ''} text-[1.10rem] flex whitespace-nowrap items-center gap-1`}>
          <Trans className='flex' i18nKey={'list_of_related_products'}>
            Lista de <b>productos relacionados</b>
          </Trans>
          {customGroup && Object.keys(customGroup)?.length > 0 &&
            <ChipWithToggle
              checked={Boolean(customGroup?.active)}
              disabled={loading || loadingData}
              onChange={(checked) => toggleActive(checked)}
              className="w-min ml-4"
              label={
                <span className='flex gap-1 items-center'>
                  {t('Status')}
                  <InfoTooltip info={customGroup?.active ? 'Related groups activated' : 'Related groups deactivated'}/>
                </span>
              }
            />
          }
        </div>

        <hr></hr>
     
        <div className="text-[1rem] font-medium">
          {t("list_title")}
        </div>

        <LanguageSelectorWithPopup 
          value={inputValue}
          placeholder={inputPlaceholder}
          translationKey={customGroup?.id} 
          translationsDone={translationsDone} 
          availableLanguages={availableLanguages} 
          languages={languages} 
          setName={setName} 
          name={name}
          updateTranslation={editProduct} 
          segmentsToTranslate={segmentsToTranslate} 
          translatedLanguages={translatedLanguages} 
          loadingTranslation={loadingTranslation} 
          setTranslatedLanguages={setTranslatedLanguages} 
        />

        <div className="text-[1rem] font-medium" style={{marginTop: "15px", display: 'flex', alignItems: 'center'}}>
          {t('select_products')}
        </div>
        <div style={{fontSize: "13px"}}>
          <SelectInput
            id={'children'}
            name={'related_products_select'}
            isMulti
            isClearable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={mapProductOptions(products)}
            options={selectorOptions}
            onChange={handleChangeOption}
            components={{Option}}
            className='max-w-[30rem]'
          />
        </div>
       
        <p style={{margin: 0}} className="text-sm mt-1">
          {t('choose_the_products_you_want_to_link')}
        </p>
        <b style={{marginTop: "-10px", marginBottom: "10px"}} className="text-sm font-semibold">{t('we do not recommend more than 4')}.</b>

        <DragDropContext onDragEnd={changeOrder}>
          <Droppable droppableId={customGroup?.id?.toString() || 'new'} style={{ overflow: "auto", maxHeight: "400px" }}>
            {providedDrop =>
              <ul ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                {products.sort((a,b) => a.order - b.order).map((product, index) =>
                  <Draggable
                    key={product.product_id}
                    draggableId={product?.product_id?.toString()}
                    index={index}
                  >
                    {providedDrag =>
                      <li
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        {...providedDrag.dragHandleProps}
                      >
                        <Icon type={IconType.DRAG}/>
                        {product.product_visible_name || product.product_name || product.product_tpv_name}
                        <Icon type={IconType.CLOSE} onClick={() => removeProduct(product.product_id)}/>
                      </li>
                    }
                  </Draggable>
                )}
                {providedDrop.placeholder}
              </ul>
              }
          </Droppable>
        </DragDropContext>
          
        {((!customGroup) || Object.keys(customGroup)?.length === 0) &&
          <EmptyStateRelatedProducts text={t('no_related_products_yet')} icon={<SquareIcon>🍃</SquareIcon>} />
        }

      </div>

    </RelatedProductsContainer>
  );
};

const Option = (props) => (
  <components.Option {...props}>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <label>{props.label}</label>
      <input
        className="checkboxRestaurantSelect"
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null()}
      />
      <span/>
    </div>
  </components.Option>
)


const EmptyStateRelatedProducts = ({text = "", icon}) => {
    
    return (
        <div className='w-full flex flex-col items-center justify-center'>
            {icon}
            <p
                style={{textAlign: "center"}}
                className='font-semibold text-[18px] p-3 text-center leading-[1.2rem] max-w-[250px]'>
                {text}
            </p>
        </div>
    )
}

