import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

toast.configure();
export const CustomToast = (text, type, onClick, children, closeTime=2000) => {
  const toast_type = type === "error" ? toast.error : toast.success 
  toast_type(
    <ToastContainer onClick={onClick}>
      <div>
      {text &&
        <p>{text}</p>
      }
      {children}
    </div>
    </ToastContainer>,
    {
      toastId: 'default-language-success',
      position: 'top-right',
      autoClose: closeTime
  })      
};

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 1rem;
  color: #222222;
  font-family: 'Poppins', sans-serif;

  p {
    text-wrap: balance;
    font-weight: 700;
    color: #094553;
  }

`;