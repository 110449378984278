import React, { useContext, useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import DraggableProductRow from './DraggableProductRow';
import { EditModifierModal } from '../Modals/EditModifierModal';
import { EditProductModal } from '../Modals/EditProductModal';
import { TPVS } from '../../DragAndDrop/constants';
import { CatalogDNDContext } from 'data/catalogDnDContext';
import { Context as AuthContext } from '../../../../../data/authContext';
import { ModifierCategoryModal } from '../ModifierCategoryModal';
import { TableContainer } from './ProductTableContainters';
import { Button } from 'common/Button';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { ObjectTypes, deleteObject } from '../api-OrderAndPay';
import { ConfirmPopup } from '../../Lockers/ConfirmPopup';
import { apiAuth } from 'logic/api';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

export const ProductsTable = ({category, setSourceList, categoriesOptions, catalog, disabled, tpv, isTspoonlab, setSectorId, sectorId, mutate}) => {
  const {
		setSelectedSuperGroup,
		setSelectedGroup,
		setSelectedCategory,
	} = useContext(CatalogDNDContext);

  const { state: { selectedRestaurant, selectedRestaurantId} } = useContext(AuthContext);

  const { t } = useTranslation();
  const sectorsAndPriceRef = useRef(null);

  const [highlightedRow, setHighlightedRow] = useState(null);
	const [productToEdit, setProductToEdit] = useState(null);
	const [modifierToEdit, setModifierToEdit] = useState(null);
	const [modifierCategoryToEdit, setModifierCategoryToEdit] = useState(null);
	const [modifierCategoryToDelete, setModifierCategoryToDelete] = useState(null);
	const [createNewProduct, setCreateNewProduct] = useState(false);
	const [showAddNew, setShowAddNew] = useState(false);
  const [loadingSectors, setLoadingSectors] = useState(false);
  const [sectorsAndPrice, setSectorsAndPrice] = useState({})
  const [openSectorsAndPrice, setOpenSectorsAndPrice] = useState(false);

  const hasPricePerSector = sectorsAndPrice && sectorsAndPrice.length > 0 && sectorsAndPrice.some((sector) => sector.fare_change_percentage > 0)

  const getSectorsAndPrice = async(signal) => {
    setLoadingSectors(true);
    try {
        const { data } = await apiAuth(`/get_sectors_and_price_per_sector/${selectedRestaurantId}`,  { method: 'GET', signal });
        setSectorsAndPrice(data);
        return data;
    } catch (error) {
      if (error.name !== 'AbortError') {  // Only log if it's not an abort
        console.error('Error fetching sectors and price info:', error);
      }
    } finally {
      setLoadingSectors(false);
    }
  }

  const closeModal = () => {
    setProductToEdit(null);
    setModifierToEdit(null);
    setModifierCategoryToEdit(null);
    setModifierCategoryToDelete(null);
    setCreateNewProduct(false);
  };

  const deleteModifierCategory = async () => {
    await deleteObject({ objectType: ObjectTypes[modifierCategoryToDelete.type], object: modifierCategoryToDelete, t });
    mutate();
  };

  const openSectorsMenu = () => {
    setOpenSectorsAndPrice((prev) => !prev)
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
  
    const fetchSectorsAndPrice = async () => {
      try {
        const sectorsAndPriceData = await getSectorsAndPrice({ signal });
        
        const hasPricePerSector = sectorsAndPriceData && sectorsAndPriceData.length > 0 && sectorsAndPriceData.some((sector) => sector.fare_change_percentage > 0);
        
        if (hasPricePerSector) {
          setSectorId(sectorsAndPriceData[0].id);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error in fetching sectors and price:', error);
        }
      }
    };
  
    fetchSectorsAndPrice(signal);
  
    return () => {
      controller.abort();
    };
  }, [selectedRestaurantId]);
  
  

  useEffect(() => {
    let isMounted = true;

    if(isMounted){
      setShowAddNew(tpv.includes(TPVS.YUMMINN) || selectedRestaurant[0].custom_catalog);
    }

    const table = document.getElementById('product_table');
    const isPopupOpen = !!productToEdit || !!modifierToEdit || !!modifierCategoryToEdit;
    
    const handleOutsideClick = (event) => {
      !isPopupOpen && !table.contains(event.target) && isMounted && setHighlightedRow(null);
    };

    table && !isPopupOpen && window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
      isMounted = false;
    }
  }, [productToEdit, modifierToEdit, modifierCategoryToEdit, tpv]);

  useEffect(() => {
    if (highlightedRow && highlightedRow.includes('PRODUCT-')) {
      sessionStorage.setItem('lastHighlightedRow', highlightedRow.replace('PRODUCT-', ''));
    }
  }, [highlightedRow]);

  useEffect(() => {
    let isMounted = true;
    const handleClickOutside = (event) => {
      if(sectorsAndPriceRef.current && !sectorsAndPriceRef.current.contains(event.target) && isMounted) {
        setOpenSectorsAndPrice(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        isMounted = false
    };
  }, []);

  useEffect(() => {
    const lastHighlightedRow = Number(sessionStorage.getItem('lastHighlightedRow') || '');
    if (!lastHighlightedRow) return;
    let isMounted;
    let newSupergroup;
    let newGroup;
    let newCategory;
  
    if (catalog.supergroups && isMounted) {
      newSupergroup = catalog.supergroups.find(supergroup => {
        newGroup = supergroup.category_groups.find(group => {
          newCategory = group.product_categories.find(category => {
            return category.products.some(product => product.id === lastHighlightedRow);
          });
          return !!newCategory;
        });
        return !!newGroup;
      });

      setSelectedSuperGroup(newSupergroup);
      setSelectedGroup(newGroup);
      setSelectedCategory(newCategory);
      setHighlightedRow(`PRODUCT-${lastHighlightedRow}`);

      return;
    } else if(isMounted) {
      newCategory = catalog.product_categories.find(category => {
        return category.products.some(product => product.id === lastHighlightedRow);
      });

      setSelectedCategory(newCategory);
      setHighlightedRow(`PRODUCT-${lastHighlightedRow}`);

      return;
    }

    return () => {
      isMounted = false;
    };
  }, [catalog]);

  useEffect(() => {
    const lastHighlightedRow = Number(sessionStorage.getItem('lastHighlightedRow') || '');
		sessionStorage.removeItem('lastHighlightedRow');
    
    document.getElementById(`PRODUCT-${lastHighlightedRow}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [category]);

  return (
    <TableContainer>
      <header>
        <h4>
          {t('products_list')}
        </h4>
        {showAddNew &&
          <Button
            leftIcon={<PlusCircleIcon/>}
            label={t(`add_new_product`)}
            onClick={() => setCreateNewProduct(true)}
          />
        }
      </header>

      {!category?.products?.length &&
        <div className={`text-gray-400 flex flex-col justify-center items-center gap-2 p-5`}>
          <b className='font-semibold'>{t('title_empty_category')}</b>
          {showAddNew &&
            <p>{t('message_empty_category')}</p>
          }
        </div>
      }

      {category?.products?.length > 0 &&
      <div>
        <table>
          <thead>
            <tr>
              <th colSpan={2}/>
              <th>{t('name')}</th>
              <th className='col_visibility'>{t('Visibility')}</th>
              <th className='col_photo'>{t('Photo')}</th>
              <th className='col_modifiers'>{t('modifier_group')}</th>
              <th className={`${hasPricePerSector ? 'cursor-pointer flex items-center gap-1 relative' : 'col_price'}`} onClick={() => openSectorsMenu()}>
                <span className={`${hasPricePerSector ? 'w-[7rem]' : ''}`}>{hasPricePerSector ? t('Price_per_sector'): t('Price')}</span>
                <i>
                  {hasPricePerSector && openSectorsAndPrice ? (
                    <ChevronUpIcon className="w-6 h-5 text-[#094553]" />
                  ) : hasPricePerSector && !openSectorsAndPrice && (
                    <ChevronDownIcon className="w-6 h-5 text-[#094553]" />
                  )}   
                </i>
                {openSectorsAndPrice && hasPricePerSector && 
                  <div ref={sectorsAndPriceRef} className="absolute bg-white px-4 flex flex-col z-10 border rounded-md shadow-lg top-full left-0 mt-2">
                  {sectorsAndPrice.map((sector, indexSectors) => 
                    <span 
                      key={indexSectors} 
                      className={`flex ${sectorId === sector.id ? 'bg-[#094553] text-white hover:bg-[#F1FFFA] hover:text-[#404040]' : ''} flex-col p-2.5 cursor-pointer transition-colors duration-300 hover:bg-[#F1FFFA] block min-h-[62px] mx-[-15px] flex items-center justify-center `}
                      onClick={() => setSectorId(sector.id)}
                    >
                      {sector.name}
                    </span>
                  )}
                  </div>
                }
              </th>
              <th className='col_edit'/>
            </tr>
          </thead>
          <Droppable droppableId="PRODUCT" direction="vertical" isDropDisabled={disabled}>
            {(providedList) =>
              <tbody
                id='product_table'
                ref={providedList.innerRef}
                style={{opacity: disabled ? '0.5' : ''}}
                {...providedList.droppableProps}
              >
                {category && category.products && category.products.map((product, index) =>
                  <DraggableProductRow
                    key={'PRODUCT-' + product.id}
                    type={'PRODUCT'}
                    item={product}
                    parentList={category.products}
                    parentName={category.visible_name}
                    index={index}
                    level={1}
                    highlightedRow={highlightedRow}
                    hasSiblings={category.products.length > 0 ? true : false}
                    isTpvYumminn={tpv.includes(TPVS.YUMMINN)}
                    setSourceList={setSourceList}
                    editProduct={() => setProductToEdit(product)}
                    setModifierToEdit={setModifierToEdit}
                    setModifierCategoryToEdit={setModifierCategoryToEdit}
                    setHighlightedRow={setHighlightedRow}
                    setModifierCategoryToDelete={setModifierCategoryToDelete}
                    hasPricePerSector={hasPricePerSector}
                  />
                )}
                {providedList.placeholder}
              </tbody>
            }
          </Droppable>
        </table>
      </div>
      }
      {(productToEdit || createNewProduct) &&
        <EditProductModal
          productToEdit={productToEdit}
          parentId={category?.id}
          tpv={tpv}
          isTspoonlab={isTspoonlab}
          categoriesOptions={categoriesOptions}
          mutate={mutate}
          closeModal={closeModal}
        />
      }
      {modifierToEdit != null &&
        <EditModifierModal
          modifier={modifierToEdit}
          tpv={tpv}
          isTspoonlab={isTspoonlab}
          closeModal={closeModal}
        />
      }
      <ModifierCategoryModal
        isOpen={!!modifierCategoryToEdit}
        category={modifierCategoryToEdit}
        mutate={mutate}
        close={closeModal}
      />
      <ConfirmPopup
        isOpen={!!modifierCategoryToDelete}
        title='remove_modifier_category'
        message={t('confirm_remove_modifier_category', modifierCategoryToDelete)}
        cancelLabel='no_cancel'
        confirmLabel='yes_remove'
        confirm={deleteModifierCategory}
        cancel={closeModal}
      />
    </TableContainer>
  );
};
